import React, { Component } from 'react'
import AboutContant from './component/MainComponent/AboutContant'
import AboutClient from './component/MainComponent/AboutClient'
import AboutProcess from './component/MainComponent/AboutProcess'
import AboutSec from './component/MainComponent/AboutSec'
import MainContact from './component/MainComponent/MainContact'
import MainCustomer from './component/MainComponent/MainCustomer'
import Footer from './include/Footer'
import Header from './include/Header'

export default class About extends Component {
    render() {
        return (
            <div>
                <Header/>
                <AboutContant/>
                <AboutSec/>
                <AboutProcess/>
                <MainCustomer/>
                <AboutClient/>
                <MainContact/>
                <Footer/>
            </div>
        )
    }
}
