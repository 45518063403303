import React, { Component } from 'react'
import smrchimg02 from "../../assets/img/concept/concept17.png"

export default class Reachgdpr extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8">
                        <div className="row text-right align-items-center">
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-3 pe-xl-15">GDPR</h3>
                                <div className="d-flex flex-row mb-6">
                                    <div>
                                        <p className="text-justify">The General Data Protection Regulation is a personal data legal framework that goes into effect May 2018. 
                                        Samcrit is compliant with GDPR and other privacy laws that govern the panelist experience. We provides respondents with the following rights, among other: the right to know their rights: the right to delete, update and access their information, the right to stop the usage of their information the right to know if there has been a data breach. 
                                        We firmly believe in upholding the rights of panelists and taking measure to secure personal data</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={smrchimg02} alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
