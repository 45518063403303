import React, { Component } from 'react'

export default class AboutContant extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8 text-center">
                        <div className="row">
                            <div className="col-xl-5 mx-auto mb-6">
                                <h1 className="display-1 mb-3">About Us</h1>
                                <p className="lead mb-0">A company turning ideas into beautiful things.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
