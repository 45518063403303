import React, { Component } from 'react'
import Img02 from '../../assets/img/concept/concept1.png'

export default class MainProcess extends Component {
    render() {
        return (
            <div>
                <section>
                    <div className="container-device pt-8">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={Img02} alt=""/></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-7">Our Three Process Steps On Creating Samcrit Member.</h3>
                                <div className="d-flex flex-row mb-6">
                                    <div>
                                        <span className="icon btn btn-circle btn-soft-primary disabled me-5"><span className="number fs-18">1</span></span>
                                    </div>
                                    <div>
                                        <h4 className="mb-1">Register As Member</h4>
                                        <p className="mb-0">Build a profile to get started Online Surveys and Start Earn Rewards for Extra Income.</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row mb-6">
                                    <div>
                                        <span className="icon btn btn-circle btn-soft-primary disabled me-5"><span className="number fs-18">2</span></span>
                                    </div>
                                    <div>
                                        <h4 className="mb-1">Earn Rewards</h4>
                                        <p className="mb-0">On Sign Up, Referrals And Survey Participation That's The Ways of Earning More And More Rewards</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <span className="icon btn btn-circle btn-soft-primary disabled me-5"><span className="number fs-18">3</span></span>
                                    </div>
                                    <div>
                                        <h4 className="mb-1">Get Paid</h4>
                                        <p className="mb-0">Redeem Your Samcrit Reward Points For Gift Cards, Vouchars, Coupons And More Offers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
