import React, { Component } from 'react'
import CF01 from "../../assets/img/concept/AU.png"
import CF02 from "../../assets/img/concept/NZ.png"

export default class Oceania extends Component {
    render() {
        return (
            <div>
                <div>
                    <h3 className="display-4 mb-5">Oceania</h3>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF01} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle", boxShadow: "0 0 3.25rem rgba(30,34,40,.04)"}} alt="Australia"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Australia</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF02} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle", boxShadow: "0 0 3.25rem rgba(30,34,40,.04)"}} alt="New Zealand"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>New Zealand</h6>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        )
    }
}
