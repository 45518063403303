import React, { Component } from 'react'

export default class InsightPostProcess extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
			<div className="container py-10">
				<div className="row gx-md-6 gy-3 gy-md-0">
					<div className="col-md-8 align-self-center text-center text-md-start navigation">
						<a href="" className="btn btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0 me-1"> Prev Post</a>
						<a href="" className="btn btn-soft-ash rounded-pill btn-icon btn-icon-end mb-0">Next Post</a>
					</div>
					<aside className="col-md-4 sidebar text-center text-md-end">
						<div className="dropdown share-dropdown btn-group">
							<button className="btn btn-red rounded-pill btn-icon btn-icon-start dropdown-toggle mb-0 me-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Share </button>
							<div className="dropdown-menu">
								<a className="dropdown-item" href="#">Twitter</a>
								<a className="dropdown-item" href="#">Facebook</a>
								<a className="dropdown-item" href="#">Linkedin</a>
							</div>
						</div>
					</aside>
				</div>
			</div>
		</section>
            </div>
        )
    }
}
