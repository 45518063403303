import React, { Component } from 'react'

export default class ServiceCommunity extends Component {
    render() {
        return (
            <div>
                <section className="wrapper image-wrapper bg-cover no-overlay bg-image text-center bg-map sm-services-community py-5">
                    <div className="container-device pt-8 pb-8">
                        <div className="row">
                            <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto pt-5">
                                <h2 className="fs-15 text-uppercase text-white mb-3">Join Our Community</h2>
                                <h3 className="display-4 mb-8 px-lg-12 text-white">We are trusted by over 5000+ clients. Join them now and grow your business.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 col-lg-9 col-xl-7 mx-auto">
                                <div className="row align-items-center counter-wrapper gy-4 gy-md-0">
                                    <div className="col-sm-4 text-center text-white">
                                        <h3 className="counter counter-lg text-white">7518</h3>
                                        <p>Completed Projects</p>
                                    </div>
                                    <div className="col-sm-4 text-center text-white">
                                        <h3 className="counter counter-lg text-white">5472</h3>
                                        <p>Satisfied Customers</p>
                                    </div>
                                    <div className="col-sm-4 text-center text-white">
                                        <h3 className="counter counter-lg text-white">2184</h3>
                                        <p>Expert Employees</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
