import React, { Component } from 'react'
import smimg02 from "../../assets/img/concept/concept9.png"

export default class ServiceMystery extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8">
                        <div className="row pb-8 text-right align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-5">Mystery Shopping</h3>
                                <p className="text-justify">services help you objectively measure the quality of your customers' experience at retail stores, restaurants, cinemas, automobile showrooms and service locations such as salons, healthcare facilities, gyms and service centres. 
                                Our Retail Audit services help you measure compliance to policies and SOPs & our Stock Audit services help you measure and reconcile inventory with your records. 
                                We use cutting edge technology to ensure accuracy and reliability of data collection, and our highly experienced and trained secret shoppers and auditors generate deep insights to help you improve your business</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
