import React, { Component } from 'react'
import WorldPanelContant from './component/MainComponent/WorldPanelContant'
import WorldPanelCountry from './component/MainComponent/WorldPanelCountry'
import WorldPanelCountry2 from './component/MainComponent/WorldPanelCountry2'
import WorldPanelSec from './component/MainComponent/WorldPanelSec'
import Footer from './include/Footer'
import Header from './include/Header'

export default class WorldPanel extends Component {
    render() {
        return (
            <div>
                <Header/>
                <WorldPanelContant/>
                <WorldPanelSec/>
                <WorldPanelCountry/>
                <WorldPanelCountry2/>
                <Footer/>
            </div>
        )
    }
}
