import React, { Component } from 'react'
import Img03 from '../../assets/img/concept/concept2.png'

export default class MainCustomer extends Component {
    render() {
        return (
            <div>
                <section>
                    <div className="container-device py-8">
                        <div className="row text-right align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={Img03} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-7 mt-lg-10">Few Reasons Why Our Valued Customers Choose Us.</h3>
                                <div className="accordion accordion-wrapper" id="accordionExample">
                                    <div className="plain accordion-item">
                                        <div id="headingOne">
                                            <h4 className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> One app participate learning opportunities. </h4>
                                        </div>
                                        <div className="">
                                            <p>Join SAMCRIT panel to take part in the most interesting and learning opportunities.</p>
                                        </div>
                                    </div>
                                    <div className="plain accordion-item">
                                        <div className="" id="headingTwo">
                                            <h4 className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Easy to earn rewards </h4>
                                        </div>
                                        <div className="">
                                            <p>To earn reward points which can be converted into some amazing voucher.</p>
                                        </div>
                                    </div>
                                    <div className="plain accordion-item">
                                        <div className="" id="headingThree">
                                            <h4 className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Signup securely </h4>
                                        </div>
                                        <div className="">
                                            <p>Sign up with your basic details and get 200 bonus points.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </section>
            </div>
        )
    }
}
