import React, { Component } from 'react'
import smimg02 from "../../assets/img/concept/concept6.png"

export default class ServiceB2C extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8">
                        <div className="row pb-8 align-items-center text-right">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-5">B2C Sampling</h3>
                                <p className="text-justify">we have the biggest online buyer board information accessible in the market with the scale, decent variety and agent to convey dependable, predictable outcomes. Access a large number of profoundly profiled shoppers, including low incidence audiences, for ex, health care, voters, new technology product owner, and other daily essintials owner. 
                                Advantage from a fantastic... Single-source informational index that is effectively figured out how to convey an assortment of favorable circumstances, including exact crow my-2d determination, unwavering quality, and prevalent attainability.
                                We center around ensuring our first-party information is involved genuine individuals with powerful, consistently revived profiles. Home Features and Improvements.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
