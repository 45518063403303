import React, { Component } from 'react'
import Logo_Black from "./../assets/img/logo_sm_black.png"
import { FaLinkedinIn, FaFacebookF, FaBars } from "react-icons/fa"

export default class Header extends Component {
    state = {
        isTop: false,
      };
    
      componentDidMount() {
        document.addEventListener('scroll', () => {
          if (window.scrollY >= 100) {
              this.setState({ isTop:true })
          }else{
              this.setState({ isTop:false })
          }
        });
      }
      btnClick() {
        window.open("https://www.panel.samcrit.com/Login");
    }
    render() {
        return (
            <div>
                <nav class="navbar navbar-expand-lg navbar-light bg-lightfixed ${(!this.state.isTop)?}">
                    <div className='container-device'>
                        <div className='row'>
                        <div className="navbar-brand col-md-3">
                            <a href={"/"}>
                                <img src={Logo_Black} alt="Samcrit" />
                            </a>
                        </div>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            {/* <span class="navbar-toggler-icon"><</span> */}<FaBars/>
                        </button>
                        <div className='col-md-9'>
                        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                                <ul class="navbar-nav ml-auto">
                                <li class="nav-item"><a class="nav-link" href={"/Services"}>Services</a></li>

                                <li class="nav-item"><a class="nav-link" href={"/Reach"}>Reach</a></li>

                                <li class="nav-item"><a class="nav-link" href={"/About"}>About us</a></li>

                                <li class="nav-item"><a class="nav-link" href={"https://in.linkedin.com/company/samcrit"} target={"_blank"}>Insights</a></li>

                                <li class="nav-item"><a className="nav-link" href={"/Contact"}>Contact</a></li>

                                <li class="nav-item"><a className="nav-link" href={"/Career"}>Career</a></li>

                                <a className='nav-link'><button className="btn btn-primary rounded btn-sm" type="button" onClick={this.btnClick.bind(this)}>
                                        Login/Signup
                                    </button>
                                </a>
                                </ul>                           
                            {/* <nav className="nav social social-muted justify-content-end text-end"> */}
                                    {/* <a href="#"><i><FaFacebookF/></i></a> */}
                                    {/* <a href="#"><i><FaLinkedinIn/></i></a>
                                </nav> */}
                        </div> 
                        </div>    
                        {/* <div class="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li><a className="nav-link" href={"https://in.linkedin.com/company/samcrit"} target={"_blank"}>Insights</a></li>
                                <li><a className="nav-link" href={"/Services"}>Services</a></li>
                                <li><a className="nav-link" href={"/Reach"}>Reach</a></li>
                                <li><a className="nav-link" href={"/About"}>About us</a></li>
                                {/* <li className="nav-item"><Link className="nav-link" to="/Blog">Blog</Link></li> */}
                                {/*<li><a className="nav-link" href={"/Contact"}>Contact</a></li>
                                <li><a className="nav-link" href={"/Career"}>Career</a></li>
                            </ul>
                        </div> */}
                        </div>
                    </div>
                </nav>
                    {/* {
                        this.state.isTop ? 

                            <nav className={`navbar navbar-expand-lg extended navbar-light navbar-bg-light caret-none banner--clone fixed ${(!this.state.isTop)?"":" banner--stick"}`}>
                                <div className="container-device flex-lg-column">
                                    {/* <div className="topbar d-flex flex-row w-100 justify-content-between align-items-center">
                                        <div className="navbar-brand">
                                            <a href={"/"}>
                                                <img src={Logo_Black} srcset={Logo_Black} alt="Samcrit" />
                                            </a>
                                        </div>
                                        <div className="navbar-other ms-auto">
                                            <ul className="navbar-nav flex-row align-items-center" data-sm-skip="true">
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="offcanvas-info">
                                                </a>
                                            </li>
                                            <li className="nav-item d-lg-none">
                                                <div className="navbar-hamburger">
                                                    <button className="hamburger animate plain" data-toggle="offcanvas-nav"><span></span></button>
                                                </div>
                                            </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* <div className="navbar-collapse-wrapper bg-white d-flex flex-row align-items-center">
                                    <div className="navbar-collapse offcanvas-nav">
                                        <div className="navbar-brand">
                                            <a href={"/"}>
                                                <img src={Logo_Black} srcset={Logo_Black} alt="Samcrit" />
                                            </a>
                                        </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item"><a className="nav-link" href={"https://in.linkedin.com/company/samcrit"} target={"_blank"}>Insights</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/Services"}>Services</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/Reach"}>Reach</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/About"}>About us</a></li>
                                    {/* <li className="nav-item"><Link className="nav-link" to="/Blog">Blog</Link></li> */}
                                    {/*<li className="nav-item"><a className="nav-link" href={"/Contact"}>Contact</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/Career"}>Career</a></li>
                                </ul>
                            </div>
                            <div className="navbar-other ms-auto w-100 d-none d-lg-block">
                                <nav className="nav social social-muted justify-content-end text-end">
                                    <a href="#"><i><FaFacebookF/></i></a>
                                    <a href="#"><i><FaLinkedinIn/></i></a>
                                </nav>
                            </div>
                                    </div>
                                </div>
                            </nav>
                        :""
                    } */}


                {/* <header className="wrapper bg-soft-primary">
                    <nav className="navbar navbar-expand-lg extended navbar-light navbar-bg-light">
                        <div className="container-device flex-lg-column">
                            {/* <div className="topbar d-flex flex-row w-100 justify-content-between align-items-center">
                            <div className="navbar-brand">                                
                                <a href={"/"}>
                                    <img src={Logo_Black} srcset={Logo_Black} alt="Samcrit" />
                                </a>
                            </div>
                            <div className="navbar-other ms-auto">
                                <div className="dropdown">
                                    <button className="btn btn-primary rounded btn-sm" type="button" onClick={this.btnClick.bind(this)}>
                                        Login/Signup
                                    </button>
                                </div>
                            </div>
                            </div> */}
                            {/* <div className="navbar-collapse-wrapper bg-white d-flex flex-row align-items-center">
                            <div className="navbar-collapse offcanvas-nav">
                                <div className="offcanvas-header navbar-brand">
                                    <div className="navbar-brand">
                                        <a href={"/"}>
                                            <img src={Logo_Black} srcset={Logo_Black} alt="Samcrit" />
                                        </a>
                                    </div> */}
                                {/* <button type="button" className="btn-close btn-close-white offcanvas-close offcanvas-nav-close" aria-label="Close"></button> */}
                                {/* </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item"><a className="nav-link" href={"/Insight"}>Insights</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/Services"}>Services</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/Reach"}>Reach</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/About"}>About us</a></li>
                                    {/* <li className="nav-item"><Link className="nav-link" to="/Blog">Blog</Link></li> */}
                                    {/*<li className="nav-item"><a className="nav-link" href={"/Contact"}>Contact</a></li>
                                    <li className="nav-item"><a className="nav-link" href={"/Career"}>Career</a></li>
                                </ul>
                            </div> */}
                            {/* <div className="navbar-other ms-auto w-100  d-lg-block">
                                <nav className="nav social social-muted justify-content-end text-end">
                                <a href="#"><i><FaFacebookF/></i></a>
                                <a href="#"><i><FaLinkedinIn/></i></a>
                                </nav>
                            </div> */}
                            {/* </div>
                        </div>
                    </nav>
                </header> */}
            </div>
        )
    }
}
