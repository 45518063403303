import React, { Component } from 'react'
import smimg03 from "../../assets/img/icons/24-hours-supports.png"
import smimg04 from "../../assets/img/icons/secure-shield.png"
import smimg05 from "../../assets/img/icons/cloud.png"
import smimg06 from "../../assets/img/icons/bar-graph.png"

export default class ServiceSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-8 pb-8">
                        <div className="row text-left mb-lg-16 align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <div className="row">
                                    <div className="col-md-5 offset-md-1 align-self-end mb-4">
                                        <div className="card bg-pale-yellow">
                                            <div className="card-body">
                                                <img src={smimg03} className="icon-svg icon-svg-md text-yellow mb-3" alt="" />
                                                <h4>B2B Sampling</h4>
                                                <p className="mb-0 text-justify">
                                                Business to businees market research is little bit challenging with maximum feasibility.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 align-self-end mb-4">
                                        <div className="card bg-pale-red">
                                            <div className="card-body">
                                                <img src={smimg04} className="icon-svg icon-svg-md text-red mb-3" alt="" />
                                                <h4>Healthcare</h4>
                                                <p className="mb-0 text-justify">The challenges in the Health care, lifescience industries are reaching and get the time of the professionals and the specialist.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="card bg-pale-leaf">
                                            <div className="card-body">
                                                <img src={smimg05} className="icon-svg icon-svg-md text-leaf mb-3" alt="" />
                                                <h4>B2C Sampling</h4>
                                                <p className="mb-0 text-justify">we have the biggest online buyer board data accessible in the market with the scale.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 align-self-start">
                                        <div className="card bg-pale-primary">
                                            <div className="card-body">
                                                <img src={smimg06} className="icon-svg icon-svg-md text-primary mb-3" alt="" />
                                                <h4>In depth interview</h4>
                                                <p className="mb-0 text-justify">A qualitative data collection method, in-depth interviews offer the opportunity to capture rich, descriptive data. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h2 className="fs-15 text-uppercase text-muted mb-3">What We Do?</h2>
                                <h3 className="display-4 mb-5 text-justify">The service we offer is specifically designed to meet your needs.</h3>
                                <p className='text-justify'>
                                Samcrit’s consumer-panel and data collection capabilities in 40+ countries help institutions and businesses analyze the market, gain valuable insight and genuinely understand consumers. Delivering online B2B, B2C, Health Care and other market aspect sample from a robust collection of high-quality data for maximum feasibility, sustainability, representativeness and consistency.</p>
                                <a href="#" className="btn btn-navy rounded-pill mt-3">More Details</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
