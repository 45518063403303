import React, { Component } from 'react'
import Img07 from '../../assets/img/concept/concept7.png'
import { FaPhoneVolume } from 'react-icons/fa'
import { GoLocation, GoMail } from 'react-icons/go'

export default class MainContact extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8 pb-6">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={Img07} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-7">Got any questions? Don't hesitate to get in touch.</h3>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1"> <i><GoLocation/></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Address</h5>
                                        <address>22, AltF Coworking, First Floor, Global Foyer, Sector-43, Gurugram, Haryana, India-122009</address>
                                        <address>Betonimiehenkatu 11 A15 Oulu 90530, Finland</address>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1"> <i><FaPhoneVolume/></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Phone</h5>
                                        <p>+91 9650801393</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-4 mt-n1"> <i><GoMail/></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">E-mail</h5>
                                        <p className="mb-0"><a href="mailto:connect@samcrit.com" className="link-body">connect@samcrit.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
