import React, { Component } from 'react'

export default class ProfileContant extends Component {
    render() {
        return (
            <div>                
                <section className="wrapper bg-soft-primary">
                    <div className="container pt-10 pb-19 pt-md-14 pb-md-15 text-center">
                        <div className="row">
                            <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                                <h1 className="display-1 mb-3">User Information</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
