import React, { Component } from 'react'
import Img08 from '../../assets/img/concept/concept18.png'
import Icons08 from '../../assets/img/icons/channel.png'
import Icons09 from '../../assets/img/icons/target-audience.png'
import Icons10 from '../../assets/img/icons/development.png'
import Icons11 from '../../assets/img/icons/monitor.png'

export default class AboutSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device py-8">
                        <div className="row text-center">
                            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 pb-8">
                                <figure><img className="w-auto about-img" src={Img08} alt="" /></figure>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <h3 className="display-5 text-center mb-5">About Samcrit</h3>
                                <p className='text-justify'>Our company focuses on providing hard-to-reach audiences with real time market research and solid foundations for all kinds of businesses. Why are we unique among panel companies globally? What makes us unique? 
                                    It’s our approach and our focus on providing hard to reach audiences. One stop solution ensuring market researchers succeed as a global aggregator of online market and social research sample sources; we create customized solutions that suit the specific research needs of each of our clients. 
                                    As the established expert in digital market research data, we analyze data, to provide innovative solution and consultative services to drive better because business results are for companies and agencies around the world. Samcrit’s consumer-panel and data collection capabilities in 40+ countries help institutions and businesses analyze the market, gain valuable insight and genuinely understand consumers. 
                                    Delivering online B2B, B2C, Health Care and other market aspect sample from a robust collection of high-quality data for maximum feasibility, sustainability, representativeness and consistency.</p>
                            </div>
                        </div>
                        <div className="row gx-lg-8 gx-xl-12 gy-6 gy-md-0 text-center">
                            <div className="col-md-6 col-lg-3">
                                <img src={Icons08} className="text-blue mb-3" alt="" />
                                <h4>Marketing</h4>
                                <p className="mb-2 text-justify">One stop solution ensuring market researchers succeed as a global aggregator of online market and social research sample sources.</p>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <img src={Icons09} className="text-green mb-3" alt="" />
                                <h4>Strategy</h4>
                                <p className="mb-2 text-justify">We create customized solutions that suit the specific research needs of each of our clients to helping in growth of clients businesses.</p>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <img src={Icons10} className="text-yellow mb-3" alt="" />
                                <h4>Development</h4>
                                <p className="mb-2 text-justify">We analyze data, to provide innovative solution and consultative services to drive better because business results are for companies and agencies around the world.</p>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <img src={Icons11} className="text-orange mb-3" alt="" />
                                <h4>Data Analysis</h4>
                                <p className="mb-2 text-justify">online B2B, B2C, Health Care and other market of high-quality data for maximum feasibility, sustainability, representativeness and consistency.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
