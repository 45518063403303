import React, { Component } from 'react'
import CF01 from "../../assets/img/concept/US.png"
import CF02 from "../../assets/img/concept/CA.png"
import CF03 from "../../assets/img/concept/MX.png"

export default class NorthAmerica extends Component {
    render() {
        return (
            <div>
                <div>
                    <h3 className="display-4 mb-5">North America</h3>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF01} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="United States of America"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>United States of America</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF02} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Canada"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Canada</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF03} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Mexico"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Mexico</h6>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        )
    }
}
