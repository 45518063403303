import React, { Component } from 'react'
import Footer from './include/Footer'
import Header from './include/Header'
import ContactContant from './component/MainComponent/ContactContant'
import ContactSec from './component/MainComponent/ContactSec'
import ContactForm from './component/MainComponent/ContactForm'

export default class Contact extends Component {
    render() {
        return (
            <div>
                <Header/>
                <ContactContant/>
                <ContactSec/>
                <ContactForm/>
                <Footer/>
            </div>
        )
    }
}
