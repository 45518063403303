import React, { Component } from 'react'
import { Link } from "react-router-dom"
import {MdOutlineKeyboardArrowRight} from "react-icons/md"


export default class WorldPanelContant extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8 text-center">
                        <div className="row">
                            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
                                <h1 className="display-1 mb-3">Samcrit World Panel</h1>
                                <nav className="d-inline-block" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li><MdOutlineKeyboardArrowRight/></li>
                                        <li className="breadcrumb-item active">World Panel</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
