import React, { Component } from 'react'
import smimg02 from '../../assets/img/concept/concept33.png'

export default class CareerSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device ">
                        <div className="row align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-5">Join Our Samcrit Team</h3>
                                <p className='text-justify'>Together at Samcrit we can reshape the market with better approach. For better discussion Join our team to be more creative and provide your productive research to reshape the industry and make our reach worldwide
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
