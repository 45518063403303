import React, { Component } from 'react'
import smrchimg01 from "../../assets/img/concept/concept16.png"

export default class ReachSec extends Component {
    render() {
        return (
            <div>
                 <section>
                    <div className="container-device py-8">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={smrchimg01} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-3">Reach Samcrit.</h3>
                                <div className="d-flex flex-row mb-6">
                                    <div>
                                        <p className="text-justify">How we approach to better Data Quality ands better approach for targeted audience:
                                        From easy to complex looking over, Samcrit has demonstrated to be the main online example supplier internationally overall crowd types. 
                                        Quality information requires the most grounded online boards, industry driving advancements and top research ability to execute impeccable information gathering. 
                                        Our Total Research Quality framework is an exhaustive incorporated arrangement of instruments, measurements, methods, and strategies that works all through the whole lifecycle of every specialist over each task we complete to guarantee our customers are accepting the most elevated quality information accessible.
                                        he framework starts some time before the information is gathered andfinishes long after the analysis is finished.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
