import React,{Component} from 'react';
import Client01 from '../../assets/img/brands/c1.png'
import Client02 from '../../assets/img/brands/c2.svg'
import Client03 from '../../assets/img/brands/c3.png'
import Client04 from '../../assets/img/brands/c4.png'
import Client05 from '../../assets/img/brands/c5.png'
import Client06 from '../../assets/img/brands/c6.png'
import Client07 from '../../assets/img/brands/c7.png'
import Client08 from '../../assets/img/brands/c8.png'
import Client09 from '../../assets/img/brands/c9.png'
import Client11 from '../../assets/img/brands/c11.png'  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
export class OwlDemo extends Component {  
        render()  
        {      
          return (  
              <div className='wrapper bg-gray'>  
            <div class='container-device py-8' > 
            <h3 className='display-4 mb-10 text-center px-xl-10 px-xxl-15'>Trusted By</h3>  
          <OwlCarousel items={3} loop={true} margin={0} autoplay ={true} autoplayTimeout={4000} autoplayHoverPause={true} >  
           <div className='box'>
               <img  className="img" src= {Client01}/>
            </div>  
           <div className='box'>
               <img  className="img" src= {Client02}/>
            </div>  
           <div className='box'>
               <img  className="img" src= {Client03}/>
            </div>  
           <div className='box'>
               <img  className="img" src= {Client04}/><p className='text-center'>Lucid</p>
            </div>  
           <div className='box'>
               <img className="img" src= {Client05}/>
            </div>  
           <div className='box'>
               <img className="img" src= {Client06}/>
            </div>  
           <div className='box'>
               <img className="img" src= {Client07}/>
            </div>  
           <div className='box'>
               <img className="img" src= {Client08}/>
            </div>  
           <div className='box'>
               <img className="img" src= {Client09}/>
            </div>  
           <div className='box'>
               <img className="img" src= {Client11}/>
            </div>  
      </OwlCarousel>  
      </div>  
      </div>  
          )  
        }  
      }  
        
  
export default OwlDemo