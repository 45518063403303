import React, { Component } from 'react'
import Img09 from '../../assets/img/concept/concept19.png'
import Icons12 from '../../assets/img/icons/light-bulb.png'
import Icons13 from '../../assets/img/icons/pie-chart.png'
import Icons14 from '../../assets/img/icons/curve.png'

export default class AboutProcess extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
					<div className="container-device py-8">
						<div className="row align-items-center">
							<div className="col-lg-7">
								<figure><img className="w-auto" src={Img09} alt="" /></figure>
							</div>
							<div className="col-lg-5">
								<h2 className="fs-15 text-uppercase text-line text-primary mb-3">How It Works?</h2>
								<h3 className="display-5 mb-7 pe-xxl-5">Everything you need on creating a business process.</h3>
								<div className="d-flex flex-row mb-4">
									<div>
										<img src={Icons12} className="icon-svg icon-svg-sm text-blue me-4" alt="" />
									</div>
									<div>
										<h4 className="mb-1">Collect Ideas</h4>
										<p className="mb-1">Nulla vitae elit libero pharetra augue dapibus.</p>
									</div>
								</div>
								<div className="d-flex flex-row mb-4">
									<div>
										<img src={Icons13} className="icon-svg icon-svg-sm text-green me-4" alt="" />
									</div>
									<div>
										<h4 className="mb-1">Data Analysis</h4>
										<p className="mb-1">Vivamus sagittis lacus augue laoreet vel.</p>
									</div>
								</div>
								<div className="d-flex flex-row">
									<div>
										<img src={Icons14} className="icon-svg icon-svg-sm text-yellow me-4" alt="" />
									</div>
									<div>
										<h4 className="mb-1">Magic Touch</h4>
										<p className="mb-0">Cras mattis consectetur purus sit amet.</p>
									</div>
								</div>
							</div>
						</div>				
					</div>
				</section>
            </div>
        )
    }
}
