import React, { Component } from 'react'
import smimg01 from "../../assets/img/concept/concept14.png"

export default class ServiceMobile extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-8">
                        <div className="row pb-8 align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={smimg01} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-6 pe-xxl-6">Mobile Services</h3>
                                <p className="text-justify">A vast majority of Americans – 95% — today own a mobile device of any kind. Not only do Americans own mobile devices, but they’re also using them more: the number of cellphone users in America has increased from 35% in 2011, to 77% (more than double!). Here’s one last statistic for you — and probably the most important of them all: When polled about survey preferences, about 65% of survey respondents said they prefer taking surveys on their mobile phones and tablets. 
                                When creating surveys to send to your clients, customers, or a research group, doesn’t it make sense to target your audience through their preferred method of \survey-taking to increase your response rates? It also helps to expedite customer service and enhance customer experience.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
