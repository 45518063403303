import React, { Component } from 'react'

export default class CareerForm extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8">
                        <div className="row align-items-center">
                            <div className="col-md-10 offset-lg-1 offset-md-2">
                                <h2 className="display-4 mb-3 text-center">Drop Your Resume</h2>
                                <p className="lead text-center mb-10">Our Team Connect with you As Soon As Possible Drop Your All Details In From.</p>
                                <form className="contact-form" method="post" action="php/contact.php">
                                    <div className="messages"></div>
                                    <div className="controls">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_name" type="text" name="name" className="form-control" placeholder="Jane" required="required" data-error="Name is required."/>
                                                    <label for="form_name">Name *</label>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_experience" type="text" name="experience" className="form-control" placeholder="2 Years" required="required" data-error="Experience is required."/>
                                                    <label for="form_experience">Experience *</label>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_email" type="email" name="email" className="form-control" placeholder="jane.doe@example.com" required="required" data-error="Valid email is required."/>
                                                    <label for="form_email">Email *</label>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_phone" type="tel" name="phone" className="form-control" placeholder="Your phone number" required="required" data-error="Phone Number is required."/>
                                                    <label for="form_phone">Phone *</label>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_job" type="text" name="job" className="form-control" placeholder="Your Job Type" required="required" data-error="Job Type is required."/>
                                                    <label for="form_job">Job Type *</label>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mb-4">
                                                    <input id="form_skill" type="text" name="skills" className="form-control" placeholder="Your Skills" required="required" data-error="Skills is required."/>
                                                    <label for="form_skill">Skills *</label>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-floating mb-4">
                                                    <input id="form_resume"type="file" name="resume" className="form-control" required="required" data-error="Resume is required."/>
                                                    <label for="form_resume" placeholder="Your resume">Resume *</label>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <input type="submit" className="btn btn-primary rounded-pill btn-send mb-3" value="Send message"/>
                                                <p className="text-muted"><strong>*</strong> These fields are required.</p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
