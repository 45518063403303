import React, { Component } from 'react'

export default class ReachContant extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8 text-center">
                        <div className="row">
                            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
                                <h1 className="display-1 mb-3">Reach Samcrit</h1>
                                <nav className="d-inline-block" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Reach</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
