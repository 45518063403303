import React, { Component } from 'react'
import CareerContant from './component/MainComponent/CareerContant'
import CareerForm from './component/MainComponent/CareerForm'
import CareerSec from './component/MainComponent/CareerSec'
import Footer from './include/Footer'
import Header from './include/Header'

export default class Career extends Component {
    render() {
        return (
            <div>
                <Header/>
                <CareerContant/>
                <CareerSec/>
                <CareerForm/>
                <Footer/>
            </div>
        )
    }
}
