import React, { Component } from 'react'
import SurveysContant from './component/MainComponent/SurveysContant'
import SurveysProcess from './component/MainComponent/SurveysProcess'
import Footer from './include/Footer'
import Header from './include/Header'

export default class Surveys extends Component {
    render() {
        return (
            <div>
                <Header/>
                <SurveysContant/>
                <SurveysProcess/>
                <Footer/>
            </div>
        )
    }
}
