import React, { Component } from 'react'
import smimg02 from "../../assets/img/concept/concept11.png"

export default class ServiceTrans extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8">
                        <div className="row pb-8 text-right align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-5">Translation</h3>
                                <p className="text-justify">We Samcrit are the provider of certified data translation to and from a large number of languages. 
                                Depending on client’s requirements we can either offer translation as a stand-alone service or as part of a larger project, along with sample, programming, hosting or other services.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
