import React, { Component } from 'react'
import HeroContant from './component/MainComponent/HeroContant'
import HeroSec from './component/MainComponent/HeroSec'
import MainContact from './component/MainComponent/MainContact'
import MainCustomer from './component/MainComponent/MainCustomer'
import MainProcess from './component/MainComponent/MainProcess'
import MainWork from './component/MainComponent/MainWork'
import AboutClient from './component/MainComponent/AboutClient'
import ServiceCommunity from './component/MainComponent/ServiceCommunity'
import Footer from './include/Footer'
import Header from './include/Header'
import "./Style.css"

export default class Main extends Component {
    render() {
        return (
            <div>
                <Header/>
                <HeroContant/>
                <HeroSec/>
                <MainProcess/>
                <MainCustomer/>
                <MainWork/>
                <ServiceCommunity/>
                <AboutClient/>
                <MainContact/>
                <Footer/>
            </div>
        )
    }
}
