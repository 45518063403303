import React, { Component } from 'react'

export default class History extends Component {
    render() {
        return (
            <div id="page-content-wrapper">
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-3 p-3">
                                        <h5 className="text-black">History</h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                            <th>Invoice</th>
                                            <th>Inv. Date</th>
                                            <th>P.O</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                            <th>Invoice</th>
                                            <th>Inv. Date</th>
                                            <th>P.O</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr>
                                                <td>SAMIN001</td>
                                                <td>28/10/2021</td>
                                                <td>SAM001</td>
                                                <td>$800</td>
                                                <td>
                                                    <span className="status purple"></span>
                                                    Review
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>SAMIN002</td>
                                                <td>28/10/2021</td>
                                                <td>SAM002</td>
                                                <td>$800</td>
                                                <td>
                                                    <span className="status orange"></span>
                                                    Pending
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>SAMIN003</td>
                                                <td>28/10/2021</td>
                                                <td>SAM003</td>
                                                <td>$800</td>
                                                <td>
                                                    <span className="status pink"></span>
                                                    In Progress
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>SAMIN004</td>
                                                <td>28/10/2021</td>
                                                <td>SAM004</td>
                                                <td>$800</td>
                                                <td>
                                                    <span className="status purple"></span>
                                                    Review
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>SAMIN005</td>
                                                <td>28/10/2021</td>
                                                <td>SAM005</td>
                                                <td>$800</td>
                                                <td>
                                                    <span className="status pink"></span>
                                                    In Progress
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
