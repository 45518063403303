import React, { Component } from 'react'
import smimg03 from "../../assets/img/concept/concept20.png"
import smimg04 from "../../assets/img/concept/concept21.png"
import smimg05 from "../../assets/img/concept/concept22.png"
import smimg06 from "../../assets/img/concept/concept23.png"
import smimg07 from "../../assets/img/concept/concept24.png"
import smimg08 from "../../assets/img/concept/concept25.png"
import smimg09 from "../../assets/img/concept/concept26.png"

export default class BlogSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog classic-view mt-n17">
                                    <article className="post">
                                        <div className="card">
                                            <figure className="card-img-top overlay overlay1 hover-scale">
                                                <a className="link-dark" href="#">
                                                    <img src={smimg03} alt=""/>
                                                </a>
                                                <figcaption>
                                                    <h5 className="from-top mb-0">Read More</h5>
                                                </figcaption>
                                            </figure>
                                            <div className="card-body">
                                                <div className="post-header">
                                                    <div className="post-category text-line">
                                                        <a href="#" className="hover" rel="category">Teamwork</a>
                                                    </div>
                                                    <h2 className="post-title mt-1 mb-0">
                                                        <a className="link-dark" href="#">Amet Dolor Bibendum Parturient Cursus</a>
                                                    </h2>
                                                </div>
                                                <div className="post-content">
                                                    <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget urna mollis ornare vel. Nulla vitae elit libero, a pharetra augue. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh. Cras mattis consectetur purus sit amet fermentum. Sed posuere consectetur.</p>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <ul className="post-meta d-flex mb-0">
                                                    <li className="post-date"><span>5 Jul 2021</span></li>
                                                    <li className="post-author"><a href="#"><span>By Samcrit</span></a></li>
                                                    <li className="post-comments"><a href="#">3<span> Comments</span></a></li>
                                                    <li className="post-likes ms-auto"><a href="#">3</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="post">
                                        <div className="card">
                                            <div className="post-slider card-img-top">
                                                <div className="basic-slider owl-carousel dots-over" data-nav="true" data-margin="5">
                                                    <div className="item">
                                                        <img src={smimg04} className="text-center" alt="" width="80%" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="post-header">
                                                    <div className="post-category text-line">
                                                        <a href="#" className="hover" rel="category">Ideas</a>
                                                    </div>
                                                    <h2 className="post-title mt-1 mb-0"><a className="link-dark" href="blog-post.html">Fringilla Ligula Pharetra Amet</a></h2>
                                                </div>
                                                <div className="post-content">
                                                    <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget urna mollis ornare vel. Nulla vitae elit libero, a pharetra augue. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh. Cras mattis consectetur purus sit amet fermentum. Sed posuere consectetur.</p>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <ul className="post-meta d-flex mb-0">
                                                    <li className="post-date"><span>25 Jun 2021</span></li>
                                                    <li className="post-author"><a href="#"><span>By Sandbox</span></a></li>
                                                    <li className="post-comments"><a href="#">5<span> Comments</span></a></li>
                                                    <li className="post-likes ms-auto"><a href="">4</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="post">
                                        <div className="card">
                                            <div className="card-img-top">
                                                <div className="item"><img src={smimg05} alt=""/></div>
                                            </div>
                                            <div className="card-body">
                                                <div className="post-header">
                                                    <div className="post-category text-line">
                                                        <a href="#" className="hover" rel="category">Workspace</a>
                                                    </div>
                                                    <h2 className="post-title mt-1 mb-0"><a className="link-dark" href="blog-post.html">Consectetur Bibendum Sollicitudin Vulputate</a></h2>
                                                </div>
                                                <div className="post-content">
                                                    <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget urna mollis ornare vel. Nulla vitae elit libero, a pharetra augue. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh. Cras mattis consectetur purus sit amet fermentum. Sed posuere consectetur.</p>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <ul className="post-meta d-flex mb-0">
                                                    <li className="post-date"><span>18 May 2021</span></li>
                                                    <li className="post-author"><a href="#"><span>By Sandbox</span></a></li>
                                                    <li className="post-comments"><a href="#">8<span> Comments</span></a></li>
                                                    <li className="post-likes ms-auto"><a href="#">6</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="blog grid grid-view">
                                    <div className="row isotope gx-md-8 gy-8 mb-8">
                                        <article className="item post col-md-6">
                                            <div className="card">
                                                <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src={smimg06} alt="" /></a>
                                                    <figcaption>
                                                        <h5 className="from-top mb-0">Read More</h5>
                                                    </figcaption>
                                                </figure>
                                                <div className="card-body">
                                                    <div className="post-header">
                                                        <div className="post-category text-line">
                                                            <a href="#" className="hover" rel="category">Coding</a>
                                                        </div>
                                                        <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Ligula tristique quis risus</a></h2>
                                                    </div>
                                                    <div className="post-content">
                                                        <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <ul className="post-meta d-flex mb-0">
                                                        <li className="post-date"><span>14 Apr 2021</span></li>
                                                        <li className="post-comments"><a href="#">4</a></li>
                                                        <li className="post-likes ms-auto"><a href="#">5</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                        <article className="item post col-md-6">
                                            <div className="card">
                                                <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src={smimg07} alt="" /></a>
                                                    <figcaption>
                                                        <h5 className="from-top mb-0">Read More</h5>
                                                    </figcaption>
                                                </figure>
                                                <div className="card-body">
                                                    <div className="post-header">
                                                        <div className="post-category text-line">
                                                            <a href="#" className="hover" rel="category">Workspace</a>
                                                        </div>
                                                        <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Nullam id dolor elit id nibh</a></h2>
                                                    </div>
                                                    <div className="post-content">
                                                        <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <ul className="post-meta d-flex mb-0">
                                                        <li className="post-date"><span>29 Mar 2021</span></li>
                                                        <li className="post-comments"><a href="#">3</a></li>
                                                        <li className="post-likes ms-auto"><a href="#">3</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                        <article className="item post col-md-6">
                                            <div className="card">
                                                <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src={smimg08} alt="" /></a>
                                                    <figcaption>
                                                        <h5 className="from-top mb-0">Read More</h5>
                                                    </figcaption>
                                                </figure>
                                                <div className="card-body">
                                                    <div className="post-header">
                                                        <div className="post-category text-line">
                                                            <a href="#" className="hover" rel="category">Meeting</a>
                                                        </div>
                                                        <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Ultricies fusce porta elit</a></h2>
                                                    </div>
                                                    <div className="post-content">
                                                        <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <ul className="post-meta d-flex mb-0">
                                                        <li className="post-date"><span>26 Feb 2021</span></li>
                                                        <li className="post-comments"><a href="#">6</a></li>
                                                        <li className="post-likes ms-auto"><a href="#">3</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                        <article className="item post col-md-6">
                                            <div className="card">
                                                <figure className="card-img-top overlay overlay1 hover-scale"><a href="#"> <img src={smimg09} alt="" /></a>
                                                    <figcaption>
                                                        <h5 className="from-top mb-0">Read More</h5>
                                                    </figcaption>
                                                </figure>
                                                <div className="card-body">
                                                    <div className="post-header">
                                                        <div className="post-category text-line">
                                                            <a href="#" className="hover" rel="category">Business Tips</a>
                                                        </div>
                                                        <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="blog-post.html">Morbi leo risus porta eget</a></h2>
                                                    </div>
                                                    <div className="post-content">
                                                        <p>Mauris convallis non ligula non interdum. Gravida vulputate convallis tempus vestibulum cras imperdiet nun eu dolor. Aenean lacinia bibendum nulla sed.</p>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <ul className="post-meta d-flex mb-0">
                                                        <li className="post-date"><span>7 Jan 2021</span></li>
                                                        <li className="post-comments"><a href="#">2</a></li>
                                                        <li className="post-likes ms-auto"><a href="#">5</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
