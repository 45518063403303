import React, { Component } from 'react'
import Asia from './Asia'
import Europe from './Europe'
import NorthAmerica from './NorthAmerica'

export default class WorldPanelCountry extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8">
                        <div className="row">
                            <div className='col-lg-4 col-xs-12'>
                                <Asia/>
                            </div>
                            <div className='col-lg-4 col-xs-12'>
                                <Europe/>
                            </div>
                            <div className='col-lg-4 col-xs-12'>
                                <NorthAmerica/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
