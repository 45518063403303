import React, { Component } from 'react'
import smimg02 from '../../assets/img/concept/concept42.png'

export default class TermsSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device py-8">
                        <div className="row text-right align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4">Our Terms And Policy</h3>
                                <p className='text-justify'>Welcome to Samcrit  and thank you for using our products and services (“Services”). The following Terms and Conditions of Use (“Terms”) govern all use of the Services, so please read carefully.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
