import React, { Component } from 'react'
import CF01 from "../../assets/img/concept/GB.png"
import CF02 from "../../assets/img/concept/DE.png"
import CF03 from "../../assets/img/concept/SE.png"
import CF04 from "../../assets/img/concept/ES.png"
import CF05 from "../../assets/img/concept/HR.png"
import CF06 from "../../assets/img/concept/FR.png"
import CF07 from "../../assets/img/concept/PL.png"
import CF08 from "../../assets/img/concept/NL.png"
import CF09 from "../../assets/img/concept/CZ.png"
import CF10 from "../../assets/img/concept/BE.png"
import CF11 from "../../assets/img/concept/IT.png"
import CF12 from "../../assets/img/concept/AT.png"
import CF13 from "../../assets/img/concept/FI.png"
import CF14 from "../../assets/img/concept/PT.png"
import CF15 from "../../assets/img/concept/CH.png"
import CF16 from "../../assets/img/concept/RU.png"

export default class Europe extends Component {
    render() {
        return (
            <div>
                <div>
                    <h3 className="display-4 mb-5">Europe</h3>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF01} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="United Kingdom"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>United Kingdom</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF02} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Germany"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Germany</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF03} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Sweden"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Sweden</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF04} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Spain"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Spain</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF05} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Hungary"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Hungary</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF06} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="France"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>France</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF07} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Poland"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Poland</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF08} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Netherlands"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Netherlands</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF09} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Czech Republic"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Czech Republic</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF10} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Belgium"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Belgium</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF11} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Italy"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Italy</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF12} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Austria"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Austria</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF13} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Finland"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Finland</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF14} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Portugal"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Portugal</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF15} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Switzerland"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Switzerland</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF16} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Russia"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Russia</h6>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        )
    }
}
