import React, { Component } from 'react'
import Banner01 from '../../assets/img/photos/pp8.png'

export default class WorldPanelSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light wrapper-border">
                    <div className="container-device pb-8">
                        <div className="row">
                            <div className="col-md-12">
                                <figure className="rounded mb-8 mb-md-12"><img src={Banner01} alt="" className="sm-world-net"/></figure>
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <h2 className="display-4 mb-3">World Panel</h2>
                                        <p className='text-justify'>We have one of the leading panels in all over the world. The best thing about our panel is it is unique and all we have verified panelist. On the continent basis here are some details regrading about panelists.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
