import React, { Component } from 'react'
import Africa from './Africa'
import Oceania from './Oceania'
import SouthAmerica from './SouthAmerica'

export default class WorldPanelCountry2 extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device py-8">
                        <div className="row gx-lg-8 gx-xl-12 gy-10">
                            <div className='col-lg-4 col-xs-12'>
                                <SouthAmerica/>
                            </div>
                            <div className='col-lg-4 col-xs-12'>
                                <Oceania/>
                            </div>
                            <div className='col-lg-4 col-xs-12'>
                                <Africa/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
