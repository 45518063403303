import React, { Component } from 'react'
import ServiceB2C from './component/MainComponent/ServiceB2C'
import ServiceCapi from './component/MainComponent/ServiceCapi'
import ServiceCAWI from './component/MainComponent/ServiceCAWI'
import ServiceCommunity from './component/MainComponent/ServiceCommunity'
import ServiceContant from './component/MainComponent/ServiceContant'
import ServiceDummy from './component/MainComponent/ServiceDummy'
import ServiceHealth from './component/MainComponent/ServiceHealth'
import ServiceIdi from './component/MainComponent/ServiceIdi'
import ServiceMobile from './component/MainComponent/ServiceMobile'
import ServiceMystery from './component/MainComponent/ServiceMystery'
import ServiceOnline from './component/MainComponent/ServiceOnline'
import ServiceProcess from './component/MainComponent/ServiceProcess'
import ServiceQues from './component/MainComponent/ServiceQues'
import ServiceSec from './component/MainComponent/ServiceSec'
import ServiceTrans from './component/MainComponent/ServiceTrans'
import Footer from './include/Footer'
import Header from './include/Header'

export default class Services extends Component {
    render() {
        return (
            <div>
                <Header/>
                <ServiceContant/>
                <ServiceSec/>
                <ServiceProcess/>
                <ServiceDummy/>
                <ServiceHealth/>
                <ServiceCAWI/>
                <ServiceB2C/>
                <ServiceIdi/>
                <ServiceMystery/>
                <ServiceCapi/>
                <ServiceTrans/>
                <ServiceOnline/>
                <ServiceMobile/>
                <ServiceQues/>
                <ServiceCommunity/>
                <Footer/>
            </div>
        )
    }
}
