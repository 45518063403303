import React, { Component } from 'react'
import smimg02 from "../../assets/img/concept/concept4.png"

export default class ServiceHealth extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8">
                        <div className="row pb-8 text-right align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-5">Healthcare</h3>
                                <p className="text-justify">The challenges in the Health care, lifescience industries are reaching and get the time of the professionals and the specialist. 
                                We Samcrit have access to the more than half Million professionals and specialist of the top health care and lifescience companies around the world and among top countries. 
                                This large consumer sampelling allow us to have access maximum medical conditions in all around the world in more than 40 countries.</p>
                            </div>
                        </div>
                    </div>
                </section>                
            </div>
        )
    }
}
