import React, { Component } from 'react'
import smimg10 from "../../assets/img/concept/concept27.png"
import smimg11 from "../../assets/img/concept/concept28.png"
import smimg12 from "../../assets/img/concept/concept29.png"
import smimg13 from "../../assets/img/concept/concept30.png"
import smimg14 from "../../assets/img/concept/concept31.png"

export default class BlogPostSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="blog single mt-n17">
                                    <div className="card">
                                        <figure className="card-img-top"><img src={smimg10} alt="" /></figure>
                                        <div className="card-body">
                                            <div className="classic-view">
                                                <article className="post">
                                                    <div className="post-content mb-5">
                                                        <h2 className="h1 mb-4">Cras mattis consectetur purus fermentum</h2>
                                                        <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.</p>
                                                        <p>Donec sed odio dui consectetur adipiscing elit. Etiam adipiscing tincidunt elit, eu convallis felis suscipit ut. Phasellus rhoncus tincidunt auctor. Nullam eu sagittis mauris. Donec non dolor ac elit aliquam tincidunt at at sapien. Aenean tortor libero, condimentum ac laoreet vitae, varius tempor nisi. Duis non arcu vel lectus urna mollis ornare vel eu leo.</p>
                                                        <div className="row g-6 mt-3 mb-10 light-gallery-wrapper">
                                                            <div className="col-md-6">
                                                                <figure className="hover-scale rounded"><a href="#" className="lightbox"> <img src={smimg11} alt="" /></a></figure>
                                                                <div className="caption d-none">
                                                                    <h5>Heading</h5>
                                                                    <p>Purus Vulputate Sem Tellus Quam</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <figure className="hover-scale rounded"><a href="#" className="lightbox"><img src={smimg12} alt="" /></a></figure>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <figure className="hover-scale rounded"><a href="#" className="lightbox"> <img src={smimg13} alt="" /></a></figure>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <figure className="hover-scale rounded"><a href="#" className="lightbox"> <img src={smimg14} alt="" /></a></figure>
                                                            </div>
                                                        </div>
                                                        <p>Maecenas sed diam eget risus varius blandit sit amet non magna. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna mollis ornare vel eu leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna.</p>
                                                        <blockquote className="fs-lg my-8">
                                                            <p>Sed posuere consectetur est at lobortis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis, est non commodo luctus, nisi erat porttitor ligula lacinia odio sem nec elit purus.</p>
                                                            <footer className="blockquote-footer">Very important person</footer>
                                                        </blockquote>
                                                        <h3 className="h2 mb-4">Sit Vulputate Bibendum Purus</h3>
                                                        <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Aenean lacinia bibendum nulla sed consectetur. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vestibulum id ligula porta felis euismod semper.</p>
                                                        <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Sed posuere consectetur est at lobortis. Donec id elit non mi porta gravida at eget metus. Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh.</p>
                                                    </div>
                                                    <div className="post-footer d-md-flex flex-md-row justify-content-md-between align-items-center mt-8">
                                                        <div>
                                                            <ul className="list-unstyled tag-list mb-0">
                                                                <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill mb-0">Still Life</a></li>
                                                                <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill mb-0">Urban</a></li>
                                                                <li><a href="#" className="btn btn-soft-ash btn-sm rounded-pill mb-0">Nature</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mb-0 mb-md-2">
                                                            <div className="dropdown share-dropdown btn-group">
                                                                <button className="btn btn-sm btn-red rounded-pill btn-icon btn-icon-start dropdown-toggle mb-0 me-0" aria-haspopup="true" aria-expanded="false">
                                                                    Share </button>
                                                                <div className="dropdown-menu">
                                                                    <a className="dropdown-item" href="#">Twitter</a>
                                                                    <a className="dropdown-item" href="#">Facebook</a>
                                                                    <a className="dropdown-item" href="#">Linkedin</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <hr />
                                            <div className="author-info d-md-flex align-items-center mb-3">
                                                <div className="d-flex align-items-center">
                                                    <figure className="user-avatar"><img className="rounded-circle" alt="" src="img/avatars/u5.jpg" /></figure>
                                                    <div>
                                                        <h6><a href="#" className="link-dark">Nikolas Brooten</a></h6>
                                                        <span className="post-meta fs-15">Sales Manager</span>
                                                    </div>
                                                </div>
                                                <div className="mt-3 mt-md-0 ms-auto">
                                                    <a href="#" className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0"> All Posts</a>
                                                </div>
                                            </div>
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Maecenas faucibus mollis interdum. Fusce dapibus, tellus ac. Maecenas faucibus mollis interdum.</p>
                                            <nav className="nav social"></nav>
                                            <hr />
                                            <h3 className="mb-6">You Might Also Like</h3>
                                            <hr />
                                            <div id="comments">
                                                <h3 className="mb-6">5 Comments</h3>
                                                <ol id="singlecomments" className="commentlist">
                                                    <li className="comment">
                                                        <div className="comment-header d-md-flex align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <figure className="user-avatar"><img className="rounded-circle" alt="" src="img/avatars/u1.jpg" /></figure>
                                                                <div>
                                                                    <h6 className="comment-author"><a href="#" className="link-dark">Connor Gibson</a></h6>
                                                                    <ul className="post-meta">
                                                                        <li>14 Jan 2021</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3 mt-md-0 ms-auto">
                                                                <a href="#" className="btn btn-soft-ash btn-sm rounded-pill btn-icon btn-icon-start mb-0"> Reply</a>
                                                            </div>
                                                        </div>
                                                        <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Sed posuere consectetur est at lobortis integer posuere erat ante.</p>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-header d-md-flex align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <figure className="user-avatar"><img className="rounded-circle" alt="" src="img/avatars/u2.jpg" /></figure>
                                                                <div>
                                                                    <h6 className="comment-author"><a href="#" className="link-dark">Nikolas Brooten</a></h6>
                                                                    <ul className="post-meta">
                                                                        <li>21 Feb 2021</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3 mt-md-0 ms-auto">
                                                                <a href="#" className="btn btn-soft-ash btn-sm rounded-pill btn-icon btn-icon-start mb-0"> Reply</a>
                                                            </div>
                                                        </div>
                                                        <p>Quisque tristique tincidunt metus non aliquam. Quisque ac risus sit amet quam sollicitudin vestibulum vitae malesuada libero. Mauris magna elit, suscipit non ornare et, blandit a tellus. Pellentesque dignissim ornare faucibus mollis.</p>
                                                        <ul className="children">
                                                            <li className="comment">
                                                                <div className="comment-header d-md-flex align-items-center">
                                                                    <div className="d-flex align-items-center">
                                                                        <figure className="user-avatar"><img className="rounded-circle" alt="" src="img/avatars/u3.jpg" /></figure>
                                                                        <div>
                                                                            <h6 className="comment-author"><a href="#" className="link-dark">Pearce Frye</a></h6>
                                                                            <ul className="post-meta">
                                                                                <li>22 Feb 2021</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-3 mt-md-0 ms-auto">
                                                                        <a href="#" className="btn btn-soft-ash btn-sm rounded-pill btn-icon btn-icon-start mb-0"> Reply</a>
                                                                    </div>
                                                                </div>
                                                                <p>Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Etiam porta sem malesuada magna mollis.</p>
                                                                <ul className="children">
                                                                    <li className="comment">
                                                                        <div className="comment-header d-md-flex align-items-center">
                                                                            <div className="d-flex align-items-center">
                                                                                <figure className="user-avatar"><img className="rounded-circle" alt="" src="img/avatars/u2.jpg" /></figure>
                                                                                <div>
                                                                                    <h6 className="comment-author"><a href="#" className="link-dark">Nikolas Brooten</a></h6>
                                                                                    <ul className="post-meta">
                                                                                        <li>4 Apr 2021</li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-3 mt-md-0 ms-auto">
                                                                                <a href="#" className="btn btn-soft-ash btn-sm rounded-pill btn-icon btn-icon-start mb-0"> Reply</a>
                                                                            </div>
                                                                        </div>
                                                                        <p>Nullam id dolor id nibh ultricies vehicula ut id. Cras mattis consectetur purus sit amet fermentum. Aenean eu leo quam. Pellentesque ornare sem lacinia aenean bibendum nulla consectetur.</p>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="comment">
                                                        <div className="comment-header d-md-flex align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <figure className="user-avatar"><img className="rounded-circle" alt="" src="img/avatars/u4.jpg" /></figure>
                                                                <div>
                                                                    <h6 className="comment-author"><a href="#" className="link-dark">Lou Bloxham</a></h6>
                                                                    <ul className="post-meta">
                                                                        <li>3 May 2021</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3 mt-md-0 ms-auto">
                                                                <a href="#" className="btn btn-soft-ash btn-sm rounded-pill btn-icon btn-icon-start mb-0"> Reply</a>
                                                            </div>
                                                        </div>
                                                        <p>Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis euismod semper. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                                                    </li>
                                                </ol>
                                            </div>
                                            <hr />
                                            <h3 className="mb-3">Would you like to share your thoughts?</h3>
                                            <p className="mb-7">Your email address will not be published. Required fields are marked *</p>
                                            <form className="comment-form">
                                                <div className="form-floating mb-4">
                                                    <input type="text" className="form-control" placeholder="Name*" id="c-name"/>
                                                    <label for="c-name">Name *</label>
                                                </div>
                                                <div className="form-floating mb-4">
                                                    <input type="email" className="form-control" placeholder="Email*" id="c-email"/>
                                                    <label for="c-email">Email*</label>
                                                </div>
                                                <div className="form-floating mb-4">
                                                    <input type="text" className="form-control" placeholder="Website" id="c-web"/>
                                                    <label for="c-web">Website</label>
                                                </div>
                                                <div className="form-floating mb-4">
                                                    <textarea name="textarea" className="form-control" rows="5" placeholder="Comment"></textarea>
                                                    <label>Comment *</label>
                                                </div>
                                                <button type="submit" className="btn btn-primary rounded-pill mb-0">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
