import React, { Component } from 'react'
import Ls01 from '../../assets/img/concept/ls01.png'

export default class SurveysProcess extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-8">
                        <div className="row text-center pt-md-16">
                            <div className="col-md-6 col-lg-3">
                                <div className="card p-3">
                                    <img src={Ls01} className="text-blue-surveys mb-3" alt="" />
                                    <h4>Share your opinion with us</h4>
                                    <p className="mb-2">Welcome to the SAMCRIT survey. â€¨We value as our member and appreciate. The time you are taking to complete the online survey</p>
                                    <h6>Rewards: 5</h6>
                                    <button type="button" className="btn btn-primary rounded btn-sm my-2">Start Survey</button>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="card p-3">
                                    <img src={Ls01} className="text-blue-surveys mb-3" alt="" />
                                    <h4>Share your opinion with us</h4>
                                    <p className="mb-2">Welcome to the SAMCRIT survey. â€¨We value as our member and appreciate. The time you are taking to complete the online survey</p>
                                    <h6>Rewards: 5</h6>
                                    <button type="button" className="btn btn-primary rounded btn-sm my-2">Start Survey</button>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="card p-3">
                                    <img src={Ls01} className="text-blue-surveys mb-3" alt="" />
                                    <h4>Share your opinion with us</h4>
                                    <p className="mb-2">Welcome to the SAMCRIT survey. â€¨We value as our member and appreciate. The time you are taking to complete the online survey</p>
                                    <h6>Rewards: 5</h6>
                                    <button type="button" className="btn btn-primary rounded btn-sm my-2">Start Survey</button>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="card p-3">
                                    <img src={Ls01} className="text-blue-surveys mb-3" alt="" />
                                    <h4>Share your opinion with us</h4>
                                    <p className="mb-2">Welcome to the SAMCRIT survey. â€¨We value as our member and appreciate. The time you are taking to complete the online survey</p>
                                    <h6>Rewards: 5</h6>
                                    <button type="button" className="btn btn-primary rounded btn-sm my-2">Start Survey</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
