import React, { Component } from 'react'
import smimg01 from "../../assets/img/concept/concept5.png"

export default class ServiceCAWI extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-8">
                        <div className="row pb-8 align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={smimg01} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-6 pe-xxl-6">(CAWI) Computer-assisted web interviewing</h3>
                                <p className="text-justify">It is a Internet surveying technique in which the interviewer follows a script provided in a website. The questionnaires are made in a program for creating web interviews. 
                                The program allows for the questionnaire to contain pictures, audio and video clips, links to different web pages. It’s considered to be a cheaper way of surveying since you don’t need to use people to hold surveys unlike Computer-assisted telephone interviewing. 
                                With the increasing use of the Internet, online questionnaires have become a popular way of collecting information.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
