import React, { Component } from 'react'
import smimg07 from "../../assets/img/icons/idea.png"
import smimg08 from "../../assets/img/icons/settings.png"
import smimg09 from "../../assets/img/icons/curve.png"

export default class ServiceProcess extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8 pb-8">
                        <div className="row text-center">
                            <div className="col-lg-9 mx-auto">
                                <h2 className="fs-15 text-uppercase text-muted mb-3">Our Process</h2>
                                <h3 className="display-4 mb-0 text-center px-xl-10 px-xxl-15">Find out everything you need to know about creating a business process model</h3>
                                <div className="row gx-lg-8 gx-xl-12 process-wrapper text-center mt-9">
                                    <div className="col-md-4"> <img src={smimg07} className="icon-svg icon-svg-md text-primary mb-3" alt="" />
                                        <h4 className="mb-1">1. Vision</h4>
                                        <p>We analyze data, to provide innovative solution.</p>
                                    </div>
                                    <div className="col-md-4"> <img src={smimg08} className="icon-svg icon-svg-md text-red mb-3" alt="" />
                                        <h4 className="mb-1">2. Avaliable</h4>
                                        <p>40+ countries help businesses analyze the market.</p>
                                    </div>
                                    <div className="col-md-4"> <img src={smimg09} className="icon-svg icon-svg-md text-leaf mb-3" alt="" />
                                        <h4 className="mb-1">3. Serving</h4>
                                        <p>Delivering online B2B, B2C, Health Care and other market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
		        </section>
            </div>
        )
    }
}
