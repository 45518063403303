import React, { Component } from 'react'
import Header from "./include/Header"
import Footer from "./include/Footer"
import TermsContant from './component/MainComponent/TermsContant'
import TermsSec from './component/MainComponent/TermsSec'
import TermsCookies from './component/MainComponent/TermsCookies'
import TermsCondition from './component/MainComponent/TermsCondition'

export default class Terms extends Component {
    render() {
        return (
            <div>
                <Header/>
                <TermsContant/>
                <TermsSec/>
                <TermsCookies/>
                <TermsCondition/>
                <Footer/>
            </div>
        )
    }
}
