import React, { Component } from 'react'

export default class TermsCondition extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device py-8">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-5">Terms and Conditions:</h3>
                                <p className='text-justify'>By utilizing Samcrit administrations, you consent to the accompanying terms with Samcrit in regards to the utilization of the administrations advertised. Before being allowed to go about as an enlisted client of, or use some other piece of these administrations, you should peruse, consent to, and acknowledge the majority of the terms in or that are connected to this Agreement. By tolerating these Terms of Use, you concur that these Terms, just as any connected records settled on a piece of this Agreement, including Samcrit’s Privacy Policy (accessible at https://www.samcrit.com/privacypolicy/), will apply at whatever point you utilize these administrations.</p>
                                <p className='text-justify'>By using the Website you agree to the Terms of Use and to abide by all rules, terms, conditions, restrictions and notices therein.</p>
                                <p className='text-justify'>Company reserves the right at any time to:</p>
                                <p className='text-justify'>Change the Terms of Use at any time;</p>
                                <p className='text-justify'>Change the Website, including terminating, eliminating, supplementing, modifying, adding to or discontinuing any content, data on or feature of the Website or the hours that the Website is available</p>
                                <p className="mb-6 text-justify">Any changes we make to the Terms of Use will be effective immediately upon notice, which we may provide by any means including, without limitation, posting on the Website and/or e-mail.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-5">Security and Confidentiality:</h3>
                                <p className='text-justify'>Utilizing these administrations may require the utilization of a client name and secret key or other type of validation where appropriated. The classification of the entrance qualifications and record itself are your duty. Any exercises that happen under clients' records are their duty. You consent to advise Samcrit quickly of any unapproved utilization of records or some other break of security. The utilization of someone else's entrance certifications is explicitly disallowed. </p>
                                <p className='text-justify'>You recognize that the utilization of the executed confirmation strategies are a satisfactory type of security. You are exclusively in charge of (1) approving, observing, controlling access to and keeping up exacting classification of your entrance accreditations, (2) not enabling someone else to utilize your entrance certifications, (3) any progressions or harm that might be acquired because of your disregard to keep up the exacting privacy of your entrance accreditations, and (4) immediately educating Samcrit recorded as a hard copy of any need to deactivate a record because of security concerns or something else. </p>
                                <p className="mb-6 text-justify">Samcrit isn't at risk for any mischief identified with the abuse or robbery of access qualifications, exposure of access accreditations, or your approval to enable someone else or element to access and utilize these administrations utilizing your entrance certifications. You will quickly inform Samcrit of any unapproved utilization of your entrance certifications and any rupture of classification. Until Samcrit gets this notice from you, you will be held at risk for any mischief following from the utilization of your record on Samcrit’s administrations.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-5">Prohibited Use and Activity</h3>
                                <p className="mb-6 text-justify">You are prohibited from using these services to damage, disable, overburden Samcrit's servers or network, impair the services or their functionality in any way, or interfere with any other party’s use of the services. Hacking, password, or any other means to gain unauthorized access to the services, user accounts, computers, or network is prohibited. Posting or transmitting any unlawful, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law is also prohibited. Samcrit will fully cooperate with any law enforcement authorities or court order requesting or directing this firm to disclose the identity of anyone posting any such information and materials.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
