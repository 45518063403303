import React, { Component } from 'react'
import Img01 from '../../assets/img/concept/concept0.png'

export default class HeroContant extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8 pb-11">
                        <div className="row mb-5 align-items-center text-right">
                            <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start order-2 order-lg-0" data-cues="slideInDown" data-group="page-title" data-delay="600">
                                <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0">Creative People, Productive Research</h1>
                                <p className="lead fs-lg mb-7">Our company focuses on providing hard-to-reach audiences with real time market research and solid foundations for all kinds of businesses.</p>
                                <div className="d-flex justify-content-center justify-content-lg-start" data-cues="slideInDown" data-group="page-title-buttons" data-delay="900">
                                    <span><a href={"https://samblogimages.s3.ap-south-1.amazonaws.com/samcritdocuments/Esomar+28+Samcrit+response+2022.pdf"} target={"_blank"} className="btn btn-primary rounded me-2">Esomar 28</a></span>
                                    <span><a href={"https://samblogimages.s3.ap-south-1.amazonaws.com/samcritdocuments/SAMCRIT+PANEL+BOOK2021.pdf"} target={"_blank"} className="btn btn-yellow rounded">Panel Book</a></span>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12" data-cue="slideInDown">
                                <figure><img className="w-auto" src={Img01} alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
