import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Main from './Main';
import About from './About';
import Services from './Services';
import Blog from './Blog';
import Contact from './Contact';
import BlogPost from './BlogPost';
// import Insight from './Insight';
import InsightPost from './InsightPost';
import Profile from './Profile';
import Surveys from './Surveys';
import Reach from './Reach';
import Career from './Career';
import Terms from './Terms';
import WorldPanel from './WorldPanel';


const { PUBLIC_URL } = process.env;

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Main/>}></Route>
      <Route exact path="/About" element={<About/>}></Route>
      <Route exact path="/Services" element={<Services/>}></Route>
      <Route exact path="/Blog" element={<Blog/>}></Route>
      <Route exact path="/Contact" element={<Contact/>}></Route>
      <Route exact path="/BlogPost" element={<BlogPost/>}></Route>
      {/* <Route exact path="/Insight" element={<Insight/>}></Route> */}
      <Route exact path="/InsightPost" element={<InsightPost/>}></Route>
      <Route exact path="/Profile" element={<Profile/>}></Route>
      <Route exact path="/Surveys" element={<Surveys/>}></Route>
      <Route exact path="/Reach" element={<Reach/>}></Route>
      <Route exact path="/Career" element={<Career/>}></Route>
      <Route exact path="/Terms" element={<Terms/>}></Route>
      <Route exact path="/WorldPanel" element={<WorldPanel/>}></Route>
    </Routes>
  </BrowserRouter>
);

export default App;
