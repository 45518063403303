import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Icons01 from '../../assets/img/icons/b2c.png'
import Icons02 from '../../assets/img/icons/b2b.png'
import Icons03 from '../../assets/img/icons/health-insurance.png'
import Icons04 from '../../assets/img/icons/job-interview.png'


export default class HeroSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-14">
                        <div className="row mt-n18">
                            <div className="col-md-6 col-xl-3">
                                <div className="card shadow-lg card-border-bottom border-soft-yellow">
                                    <div className="card-body">
                                        <img src={Icons01} className="text-yellow mb-3" alt="" />
                                        <h4>B2C Sampling</h4>                                
                                        <p className="mb-2 text-justify">we have the biggest online buyer board information accessible in the market with the predictable outcomes. </p>
                                        <Link to='/Services' className="more hover link-yellow">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        <img src={Icons02} className="text-green mb-3" alt="" />
                                        <h4>B2B Sampling</h4>
                                        <p className="mb-2 text-justify">Business to businees market research is little bit challenging with maximum feasibility.</p>
                                        <Link to='/Services' className="more hover link-green">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        <img src={Icons03} className="text-orange mb-3" alt="" />
                                        <h4>Health</h4>
                                        <p className="mb-2 text-justify">The challenges in the Health care, lifescience industries are reaching and get the time of the specialist.</p>
                                        <Link to='/Services' className="more hover link-orange">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <div className="card shadow-lg">
                                    <div className="card-body">
                                        <img src={Icons04} className="text-blue mb-3" alt="" />
                                        <h4>In depth interview</h4>
                                        <p className="mb-2 text-justify">A qualitative data collection method, in-depth interviews offer the opportunity to capture complex processes.</p>
                                        <Link to='/Services' className="more hover link-blue">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
