import React, { Component } from 'react'
import ReachContant from './component/MainComponent/ReachContant'
import Reachgdpr from './component/MainComponent/Reachgdpr'
import ReachMethdology from './component/MainComponent/ReachMethdology'
import ReachPdp from './component/MainComponent/ReachPdp'
import ReachSec from './component/MainComponent/ReachSec'
import Footer from './include/Footer'
import Header from './include/Header'

export default class Reach extends Component {
    render() {
        return (
            <div>
                <Header/>
                <ReachContant/>
                <ReachSec/>
                <Reachgdpr/>
                <ReachMethdology/>
                <ReachPdp/>
                <Footer/>
            </div>
        )
    }
}
