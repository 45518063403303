import React, { Component } from 'react'
import InsightPostContant from './component/MainComponent/InsightPostContant'
import InsightPostProcess from './component/MainComponent/InsightPostProcess'
import InsightPostSec from './component/MainComponent/InsightPostSec'
import Footer from './include/Footer'
import Header from './include/Header'

export default class InsightPost extends Component {
    render() {
        return (
            <div>
                <Header/>
                <InsightPostContant/>
                <InsightPostSec/>
                <InsightPostProcess/>
                <Footer/>
            </div>
        )
    }
}
