import React, { Component } from 'react'
import smrchimg03 from "../../assets/img/concept/concept40.png"
import smrchimg04 from "../../assets/img/concept/concept41.png"

export default class ReachMethdology extends Component {
    render() {
        return (
            <div>
                <section>
                    <div className="container-device py-8">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                            <h3 className="display-4 mb-3">Methdology</h3>
                                <figure><img className="w-auto" src={smrchimg03} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                            <h3 className="display-4 mb-3 pe-xl-15"> </h3>
                                <figure><img className="w-auto ms-18" src={smrchimg04} alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
