import React, { Component } from 'react'
import CF01 from "../../assets/img/concept/ZA.png"

export default class Africa extends Component {
    render() {
        return (
            <div>
                <div>
                    <h3 className="display-4 mb-5">North America</h3>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF01} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="South Africa"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>South Africa</h6>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        )
    }
}
