import React, { Component } from 'react'
import CF01 from "../../assets/img/concept/JP.png"
import CF02 from "../../assets/img/concept/KR.png"
import CF03 from "../../assets/img/concept/ID.png"
import CF04 from "../../assets/img/concept/PH.png"
import CF05 from "../../assets/img/concept/MY.jpg"
import CF06 from "../../assets/img/concept/TH.png"
import CF07 from "../../assets/img/concept/VN.png"
import CF08 from "../../assets/img/concept/IN.png"
import CF09 from "../../assets/img/concept/SG.png"
import CF10 from "../../assets/img/concept/IL.png"
import CF11 from "../../assets/img/concept/SA.png"
import CF12 from "../../assets/img/concept/JO.png"
import CF13 from "../../assets/img/concept/AE.jpg"

export default class Asia extends Component {
    render() {
        return (
            <div>
                <div>
                    <h3 className="display-4 mb-5">Asia</h3>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF01} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Japan"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Japan</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF02} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="South Korea"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>South Korea</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF03} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Indonesia"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Indonesia</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF04} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Phillipines"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Phillipines</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF05} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Malaysia"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Malaysia</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF06} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Thailand"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Thailand</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF07} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Vietnam"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Vietnam</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF08} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="India"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>India</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF09} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Singapore"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Singapore</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF10} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Israel"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Israel</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF11} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Saudi Arabia"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Saudi Arabia</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF12} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="Jordan"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Jordan</h6>
                            </div>
                        </div>
                    </li>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF13} className='shadow-lg' style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle"}} alt="UAE"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>UAE</h6>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        )
    }
}
