import React, { Component } from 'react'
import smimg02 from "../../assets/img/concept/concept13.png"

export default class ServiceOnline extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8">
                        <div className="row pb-8 text-right align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-5">Online Product Test</h3>
                                <p className="text-justify">An online product test is a type of CAWI study that involves shipping out a physical product to a certain pool of respondents who are required to test that product for a specific amount of time and summarize their experience in a subsequent online survey. 
                                The product can be pretty much anything from food to cosmetics and detergents. Some countries do not accept certain products, so please get in touch with us if you have any query in this regard.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
