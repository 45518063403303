import React, { Component } from 'react'
import History from './History'

export default class ProfileSec extends Component {
    render() {
        return (
            <div>
               <section>
                  <div>
                     <div className="container">
                        <div className="row ">
                           <div className="profile-nav col-md-3">
                              <div className="panel shadow">
                                 <div className="user-heading round">
                                    <a href="#"><img src="https://samblogimages.s3.ap-south-1.amazonaws.com/ProfileImages/user-profile.png" alt=""/></a>
                                    <h1>will smith</h1>
                                    <p>wills3044@gmail.com</p>
                                 </div>
                                 <ul className="nav nav-pills nav-stacked" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <li className="active">
                                       <a className="nav-link active" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true">
                                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
                                          </svg>
                                          Profile
                                       </a>
                                    </li>
                                    <li>
                                       <a className="nav-link" id="v-pills-edit-profile-tab" data-toggle="pill" href="#v-pills-edit-profile" role="tab" aria-controls="v-pills-edit-profile" aria-selected="false">
                                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                          </svg>
                                          Edit profile
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <div className="profile-info col-md-9 tab-content" id="v-pills-tabContent">
                              <div className="tab-pane fade active show" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                 <div className="panel shadow">
                                    <div className="bio-graph-heading">
                                       <h1 className='text-white'>Profile Details</h1>
                                    </div>
                                    <div className="panel-body container bio-graph-info">
                                       <div className="row">
                                          <div className="bio-row">
                                             <p><span>First Name </span>: will</p>
                                          </div>
                                          <div className="bio-row">
                                             <p><span>Last Name </span>: smith</p>
                                          </div>
                                          <div className="bio-row">
                                             <p><span>Country </span>: India</p>
                                          </div>
                                          <div className="bio-row">
                                             <p><span>Birthday</span>: 23rd August 1990</p>
                                          </div>
                                          <div className="bio-row">
                                             <p><span>Gender </span>: Male</p>
                                          </div>
                                          <div className="bio-row">
                                             <p><span>Email </span>: wills3044@gmail.com</p>
                                          </div>
                                          <div className="bio-row">
                                             <p><span>Mobile </span>: 0123456789</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div>
                                    <div className="row">
                                       <div className="col-md-6 mt-3">
                                          <div className="panel h-100 pt-3 shadow">
                                             <div className="panel-body">
                                                <div className="bio-chart">
                                                   <div className="bio-input">
                                                      <canvas width="100px" height="100px"></canvas>
                                                      <input className="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" data-fgcolor="#e06b7d" data-bgcolor="#e8e8e8" value="35"/>
                                                   </div>
                                                </div>
                                                <div className="bio-desk">
                                                   <h4 className="red font-weight-bold">Total Survey</h4>
                                                   <p>Started : 15 July</p>
                                                   <p>Deadline : 15 August</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6 mt-3">
                                          <div className="panel h-100 pt-3 shadow">
                                             <div className="panel-body">
                                                <div className="bio-chart">
                                                   <div className="bio-input">
                                                      <canvas width="100px" height="100px"></canvas>
                                                      <input className="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" data-fgcolor="#4CC5CD" data-bgcolor="#e8e8e8" value="63"/>
                                                   </div>
                                                </div>
                                                <div className="bio-desk">
                                                   <h4 className="terques font-weight-bold">Incomplete Survey </h4>
                                                   <p>Started : 15 July</p>
                                                   <p>Deadline : 15 August</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6 mt-3">
                                          <div className="panel h-100 pt-3 shadow">
                                             <div className="panel-body">
                                                <div className="bio-chart">
                                                   <div className="bio-input">
                                                      <canvas width="100px" height="100px"></canvas>
                                                      <input className="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" data-fgcolor="#96be4b" data-bgcolor="#e8e8e8" value="75"/>
                                                   </div>
                                                </div>
                                                <div className="bio-desk">
                                                   <h4 className="green font-weight-bold">Complete Survey</h4>
                                                   <p>Started : 15 July</p>
                                                   <p>Deadline : 15 August</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-6 mt-3">
                                          <div className="panel h-100 pt-3 shadow">
                                             <div className="panel-body">
                                                <div className="bio-chart">
                                                   <div className="bio-input">
                                                      <canvas width="100px" height="100px"></canvas>
                                                      <input className="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" data-fgcolor="#cba4db" data-bgcolor="#e8e8e8" value="50"/>
                                                   </div>
                                                </div>
                                                <div className="bio-desk">
                                                   <h4 className="purple font-weight-bold">Best Survey</h4>
                                                   <p>Started : 15 July</p>
                                                   <p>Deadline : 15 August</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="panel shadow mt-3">
                                    <div className="bio-graph-heading">
                                       <h1 className='text-white'>History</h1>
                                    </div>
                                    <div className="panel-body container bio-graph-info">
                                       <History/>
                                    </div>
                                 </div>
                              </div>
                              <div className="tab-pane fade" id="v-pills-edit-profile" role="tabpanel" aria-labelledby="v-pills-edit-profile-tab">
                                 <div className="panel shadow">
                                    <div className="bio-graph-heading">
                                       <h1 className="text-white">Edit Profile Details</h1>
                                    </div>
                                    <div className="panel-body container bio-graph-info">
                                       <div className="row">
                                          <div className="col-md-12">
                                             <div id="errorBox" className="alert alert-danger alert-dismissible fade show" style={{ display: "none" }}><strong></strong> <button type="button" className="close" data-dismiss="alert">×</button></div>
                                             <div id="successBox" className="alert alert-success alert-dismissible fade show" style={{ display: "none" }}><strong></strong> <button type="button" className="close" data-dismiss="alert">×</button></div>
                                             <form>
                                                <div className="form-row">
                                                   <div className="form-group col-md-6"><label for="inputEmail4">First Name</label><input type="text" className="form-control" name="firstname" placeholder="Firstname" value="will"/></div>
                                                   <div className="form-group col-md-6"><label for="inputPassword4">Last Name</label><input type="text" className="form-control" name="lastname" placeholder="Lastname" value="smith"/></div>
                                                </div>
                                                <div className="form-row">
                                                   <div className="form-group col-md-6"><label for="inputEmail4">Mobile</label><input type="text" className="form-control" name="mobile" placeholder="Email" value="0123456789"/></div>
                                                   <div className="form-group col-md-6"><label for="inputPassword4">DOB</label><input type="date" className="form-control" name="dob" placeholder="Date" value="1990-08-23"/></div>
                                                </div>
                                                <div className="form-row">
                                                   <div className="form-group col-md-6">
                                                      <label for="inputEmail4">Country</label>
                                                      <div className=" css-2b097c-container">
                                                         <span aria-live="polite" aria-atomic="false" aria-relevant="additions text" className="css-7pg0cj-a11yText"></span>
                                                         <div className=" css-yk16xz-control">
                                                            <div className=" css-1hwfws3">
                                                               <div className=" css-1uccc91-singleValue">India</div>
                                                               <div className="css-1g6gooi">
                                                                  <div className="" style={{display: "inline-block"}}>
                                                                     <input autocapitalize="none" autocomplete="off" autocorrect="off" id="react-select-2-input" spellcheck="false" tabindex="0" type="text" aria-autocomplete="list" style={{ boxSizing: "content-box", width: "2px", background: "rgba(0, 0, 0, 0) none repeat scroll 0px center", border: "0px none", fontSize: "inherit", opacity:"1", outline: "currentcolor none 0px", padding: "0px", color: "inherit"}} value=""/>
                                                                     <div style={{position: "absolute", top: "0px", left: "0px", visibility: "hidden", height: "0px", overflow: "scroll", whiteSpace: "pre", fontSize: "16px", fontFamily: "-apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, &quot;Helvetica Neue&quot;, Arial, sans-serif, &quot;Apple Color Emoji&quot;, &quot;Segoe UI Emoji&quot;, &quot;Segoe UI Symbol&quot;;", fontWeight: "400", fontStyle: "normal", letterSpacing: "normal", textTransform: "none"}}></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div className=" css-1wy0on6">
                                                               <span className=" css-1okebmr-indicatorSeparator"></span>
                                                               <div className=" css-tlfecz-indicatorContainer" aria-hidden="true">
                                                                  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-8mmkcg">
                                                                     <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                                                  </svg>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <input name="country" type="hidden" value="India"/>
                                                      </div>
                                                   </div>
                                                   <div className="form-group col-md-6"><label for="inputPassword4">State</label><input type="text" className="form-control" name="state" placeholder="Enter State" value=""/></div>
                                                </div>
                                                <div className="form-row">
                                                   <div className="form-group col-md-6"><label for="inputEmail4">Postal Code</label><input type="number" className="form-control" name="postalcode" placeholder="110001" value=""/></div>
                                                   <div className="col-md-6">
                                                      <label for="inputEmail4" className="d-block">Gender</label>
                                                      <div className="form-check form-check-inline"><input className="form-check-input" type="radio" name="gender" value="M"/><label className="form-check-label" for="flexRadioDefault1">M</label></div>
                                                      <div className="form-check form-check-inline"><input className="form-check-input" type="radio" name="gender" value="F"/><label className="form-check-label" for="flexRadioDefault2">F</label></div>
                                                   </div>
                                                   <div className="form-group col-md-12"><label for="inputPassword4">Address</label><input type="text" className="form-control" name="address" placeholder="Address" value=""/></div>
                                                </div>
                                                <button type="submit" className="btn btn-primary">Update</button>
                                             </form>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
        )
    }
}
