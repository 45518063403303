import React, { Component } from 'react'
import smimg01 from "../../assets/img/concept/concept3.png"

export default class ServiceDummy extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-8">
                        <div className="row pb-6 align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={smimg01} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-6 pe-xxl-6">B2B Sampling</h3>
                                <p className="mb-6 text-justify">Business to businees market research is little bit challenging with maximum feasibility. We in Samcrit know how to approch to business professional to collect data and feedback with tough decision to improve quality of your services and products. 
                                Being among the leading B2B data provider we have wide range of pofile atributes and audience. Our approch to B2B business professionals, such as: <br/>Area where we serve:</p>
                                <ul className="progress-list mt-2">
                                    <li>
                                        <p>IT Decision Makers</p>
                                    </li>
                                    <li>
                                        <p>Financial Analysts</p>
                                    </li>
                                    <li>
                                        <p>HR Decision Makers</p>
                                    </li>
                                    <li>
                                        <p>C-Level Executives</p>
                                    </li>
                                    <li>
                                        <p>Small Business Owners</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
