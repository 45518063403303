import React, { Component } from 'react'
import BlogContant from './component/MainComponent/BlogContant'
import BlogSec from './component/MainComponent/BlogSec'
import Footer from './include/Footer'
import Header from './include/Header'

export default class Blog extends Component {
    render() {
        return (
            <div>
                <Header/>
                <BlogContant/>
                <BlogSec/>
                <Footer/>
            </div>
        )
    }
}
