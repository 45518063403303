import React, { Component } from 'react'
import { Link } from "react-router-dom"
import smcoimg01 from '../../assets/img/concept/concept32.png'
import { FaPhoneVolume } from 'react-icons/fa'
import { GoLocation, GoMail } from 'react-icons/go'

export default class ContactSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light angled upper-end">
                    <div className="container-device py-8">
                        <div className="row align-items-center">
                            <div className="col-lg-7 position-relative">
                                <div className="row">
                                    <div className="col-md-8">
                                        <figure className="rounded position-relative"><img src={smcoimg01} alt=""/></figure>
                                    </div>
                                    <div className="col-md-4 mt-14">
                                        <div className="card bg-pale-primary text-center counter-wrapper">
                                            <div className="card-body p-4">
                                                <h3 className="counter text-nowrap">5000+</h3>
                                                <p className="mb-0" style={{display: "ruby"}}>Satisfied Customers</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <h2 className="display-4 mb-8">Convinced yet? Let's make something great together.</h2>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-6 mt-n1"> <i><GoLocation/></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Address</h5>
                                        <address>22, AltF Coworking, First Floor, Global Foyer, Sector-43, Gurugram, Haryana, India-122009</address>
                                        <address>Betonimiehenkatu 11 A15 Oulu 90530, Finland</address>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-6 mt-n1"> <i><FaPhoneVolume/></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Phone</h5>
                                        <p>+91 9650801393</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row">
                                    <div>
                                        <div className="icon text-primary fs-28 me-6 mt-n1"> <i><GoMail/></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">E-mail</h5>
                                        <p className="mb-0"><Link to="mailto:connect@samcrit.com" className="link-body">connect@samcrit.com</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
