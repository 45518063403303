import React, { Component } from 'react'
import smimg01 from "../../assets/img/concept/concept8.png"

export default class ServiceIdi extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-8">
                        <div className="row pb-8 align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={smimg01} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-6">In depth interview</h3>
                                <p className="text-justify">A qualitative data collection method, in-depth interviews offer the opportunity to capture rich, descriptive data about how people think and behave, and unfolding complex processes. 
                                They can be used as a standalone research method or as part of a multi method design, depending on the needs of the research. In depth interviews are normally carried out face to face so that a rapport can be created with respondents. Body language is also used to add a high level of understanding to the answers. 
                                Telephones can also be used by a skilled researcher with little loss of data and at a tenth of the cost.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
