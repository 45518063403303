import React, { Component } from 'react'
import ProfileContant from './component/MainComponent/ProfileContant'
import ProfileSec from './component/MainComponent/ProfileSec'
import Footer from './include/Footer'
import Header from './include/Header'

export default class Profile extends Component {
    render() {
        return (
            <div>
                <Header/>
                <ProfileContant/>
                <ProfileSec/>
                <Footer/>
            </div>
        )
    }
}
