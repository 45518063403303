import React, { Component } from 'react'
import smimg01 from "../../assets/img/concept/concept10.png"

export default class ServiceCapi extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light">
                    <div className="container-device pt-8">
                        <div className="row pb-8 align-items-center">
                            <div className="col-lg-7">
                                <figure><img className="w-auto" src={smimg01} alt="" /></figure>
                            </div>
                            <div className="col-lg-5">
                                <h3 className="display-4 mb-6">(CAPI) Computer-assisted personal interview</h3>
                                <p className="text-justify">When it comes to conducting your research surveys, We have many versatile tools to choose from, including CATI telephone interviews and CAWI web interviews. But what about those times when you need to conduct face-to-face interviews? 
                                This is a situation in which it’s crucial then there CAPI comes, with this we can conduct your surveys, even in the absence of traditional surveying technology such as a telephone or Internet connection.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
