import React, { Component } from 'react'
import Logo_White from "./../assets/img/logo_sm_white.png"
import { FaLinkedinIn, FaFacebookF, FaYoutube } from "react-icons/fa"
import { Link } from "react-router-dom"

export default class Footer extends Component {
      btnClick() {
        window.open("https://www.panel.samcrit.com/Login");
    }
    render() {
        return (
            <div>
                <footer className="bg-navy text-inverse">
                    <div className="container-device py-8">
                        <div className="d-lg-flex flex-row align-items-lg-center">
                            <h3 className="display-4 mb-6 text-white">Join our community by using our services and grow your business.</h3>
                            <Link className="btn btn-primary rounded-pill mb-0 text-nowrap" to="/Contact">Try It For Free</Link>
                        </div>
                        <hr className="" />
                        <div className="row">
                            <div className="col-md-4 col-lg-3">
                                <div className="widget">
                                    <Link to="/"><img className="mb-4" src={Logo_White} alt="" /></Link>
                                    <p className="mb-4">© 2022 Samcrit. <br className="d-none d-lg-block" />All rights reserved.</p>
                                    <nav className="nav social social-white">
                                        <a href="#"><i><FaLinkedinIn/></i></a>
                                        {/* <a href="#"><i><FaFacebookF/></i></a>
                                        <a href="#"><i><FaYoutube/></i></a> */}
                                    </nav>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title text-white mb-3">Get in Touch</h4>
                                    <address className="pe-xl-15 pe-xxl-17">
                                        22, AltF Coworking, First Floor, Global Foyer, Sector-43, Gurugram, Haryana, India-122009
                                    </address>
                                    <a href="mailto:#">connect@samcrit.com</a><br /> +91 9650801393
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title text-white mb-3">Learn More</h4>
                                    <ul className="list-unstyled  mb-0">
                                        <li><Link to="#" onClick={this.btnClick.bind(this)}>Join Our Panel</Link></li>
                                        <li><Link to="/">Case Study</Link></li>
                                        <li><Link to="/WorldPanel">Panel Capabilites</Link></li>
                                        <li><Link to="/Terms">Terms of Use</Link></li>
                                        <li><Link to="/">Career</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
                                    <p className="mb-5">Subscribe to our newsletter to get our news & Updates to you.</p>
                                    <div className="newsletter-wrapper">
                                        <div id="sc_embed_signup2">
                                            <form action="#" method="#" className="validate dark-fields" target="_blank" novalidate>
                                                <div id="sc_embed_signup_scroll2">
                                                    <div className="sc-field-group input-group form-floating">
                                                        <input type="email" value="" name="EMAIL" className="required email form-control" placeholder="Email Address"/>
                                                        <label for="EMAIL2">Email Address</label>
                                                        <input type="button" value="Join" name="subscribe" className="btn btn-primary"/>
                                                    </div>
                                                    <div className="clear">
                                                        <div className="response" style={{ display:"none" }}></div>
                                                        <div className="response" style={{ display:"none" }}></div>
                                                    </div>
                                                    <div style={{ position: "absolute", left: "-5000px", ariaHidden:"true" }}><input type="text" tabindex="-1" value=""/></div>
                                                    <div className="clear"></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}
