import React, { Component } from 'react'

export default class TermsCookies extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">Cookies</h3>
                                <p className="mb-6 text-justify">We use cookies to improve our website and send you personalized communications. By browsing Samcrit you are accepting our cookies policy. 
                                We won't track your information when you visit our site. But in order to comply with your preferences, we'll have to use just one tiny cookie so that you're not asked to make this choice again.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">Who we are?</h3>
                                <p className="mb-6 text-justify">Our company focuses on providing hard-to-reach audiences with real time market research and solid foundations for all kinds of businesses. 
                                One stop solution ensuring market researchers succeed as a global aggregator of online market and social research sample sources;
                                we create customized solutions that suit the specific research needs of each of our clients. 
                                As the established expert in digital market research data, we analyze data, to provide innovative solution and consultative services to drive better because business results are for companies and agencies around the world</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">Protecting you data:</h3>
                                <p>Samcrit is firmly committed to protecting all citizens' right to privacy. When you share your personal information with us, we believe that it is our duty to ensure that it is adequately protected. This privacy policy will tell you what information we collect about you, with what purpose, and how we protect it</p>
                                <p>This privacy policy applies to customers, people interested in our business, and candidates who participate in any of our recruitment selection processes. However, it does not apply to the members of the consumer panels created by Samcrit</p>
                                <p className="mb-6 text-justify">Whenever you need to, you can exercise your rights in relation to your personal data by sending an email to connect@samcrit.com </p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">What type of information we collect?</h3>
                                <p className='text-justify'>The information we receive, that you voluntarily provide as part of our research studies, generally includes certain demographic information (age, gender, and/or income level) that is only used to analyze data, information regarding your personal preferences and attitudes, as well as your specific purchase activities or hobbies. 
                                Please note, the information we collect is always strictly for use in research studies and Samcrit will never ask for financial account numbers or personal passwords.</p>
                                <p className='text-justify'>We use the unique identifier to assist our clients in safeguarding the integrity of survey results to ensure that one person does not complete a survey more than one time. The third-party provider may use and analyze publicly available information and data obtained from the computer’s web browser and from other publicly available data points, including, without limitation, the technical settings of the computer, the characteristics of the computer or web browser to create a unique identifier assigned to the computer. 
                                    This technology may utilize both cookies as well as locally shared objects known as “flash cookies”. The unique identifier is an alpha-numeric ID. 
                                    Additionally, to assist our clients in protecting and ensuring the integrity of survey results,</p>
                                <p className='text-justify'>We may link or associate your unique identifier to you and any of your personally identifiable information. </p>
                                <p className='text-justify'>We may share your unique identifier with sample or panel providers. </p>
                                <p className="mb-6 text-justify">We may receive or obtain a unique identifier linked to you from a third-party, including, without limitation, a sample or panel provider or a client of Samcrit. Any unique identifier(s) received or obtained by Samcrit and linked to a specific individual will be protected in accordance with this Privacy Policy.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">How do we collect data?</h3>
                                <p className='text-justify'>When someone visit our website we may use his or her browser details, IP address, cookies, Location as well and details filled by him or her on the website. </p>
                                <p className="mb-6 text-justify">When you visit our website weather by invitation email or someone redirects you to our website we may use your data as mentioned above or when our professionals or sales person contacts you.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">How We Use Your Information:</h3>
                                <p className="mb-6 text-justify">We only use your information for research purpose. Samcrit will never sell your information to anyone and never attempt to sell our participant anything; 
                                we are here only for gathering the information mostly provided by you. When you complete a research project, your personal responses are analyzed collectively with all other respondents’ information and not reported individually, but in the aggregate. 
                                We support the rights of our survey participants by limiting the use of personally identifiable information for legitimate marketing research purposes and actively support industry organizations created to uphold ethical survey research. We will never share our survey participants’ email addresses or personally identifiable information with anyone except our clients. Access to this data is strictly for purposes related to carrying out the research study, including hosting a survey or processing data. 
                                Samcrit will obtain guarantees from our vendors that they will safeguard personal information in consistency with this policy. </p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">Your Access to and Control over Information:</h3>
                                <p className='text-justify'>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via our contact page:</p>
                                <p className='text-justify'>See what data we have about you, if any.</p>
                                <p className='text-justify'>Change/correct any data we have about you.</p>
                                <p className='text-justify'>Have us delete any data we have about you.</p>
                                <p className="mb-6 text-justify">Express any concern you have about our use of your data.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">The data protection clause:</h3>
                                <p className='text-justify'>We take the security of your personal data and information seriously. We implemented all reasonable security measure to protect your information any of the third parties. These measures are intended to prevent your personal data from being lost or being accessed by an unauthorized third party. We take precautions to protect personally identifiable information in our possession from loss, disclosure, alteration and destruction, misuse and unauthorized access.</p>
                                <p className='text-justify'>Data retention policy:</p>
                                <p className="mb-6 text-justify">Due to our intention to minimize the use of personal data, we are committed to eliminate all information about you that is not strictly necessary to provide our services. As well as erase it, in any case, after a long period of time since our last contact.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">Legal Obligations:</h3>
                                <p className="mb-6 text-justify">Under certain circumstances permitted or required by law (for example, in connection with law enforcement investigations), we may be required to disclose client data or personal information without giving notice.</p>
                            </div>
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-6 pe-xxl-6">Changes in the Privacy Policy:</h3>
                                <p className="mb-6 text-justify">We reserve the right to modify this privacy policy at any time. If we decide to change our policy, we will prominently post those changes here and any other place we deem appropriate, so you are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. If we make any material changes, we will notify you either by way of an email or by a notice on our website. We will use information in accordance with the policy as it was in effect at the time information was collected.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
