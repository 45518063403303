import React, { Component } from 'react'

export default class BlogPostContant extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                        <div className="row">
                            <div className="col-md-10 col-xl-8 mx-auto">
                                <div className="post-header">
                                    <div className="post-category text-line">
                                        <a href="#" className="hover" rel="category">Teamwork</a>
                                    </div>
                                    <h1 className="display-1 mb-4">Commodo Dolor Bibendum Parturient Cursus Mollis</h1>
                                    <ul className="post-meta mb-5">
                                        <li className="post-date"><span>5 Jul 2021</span></li>
                                        <li className="post-author"><a href="#"><span>By Sandbox</span></a></li>
                                        <li className="post-comments"><a href="#">3<span> Comments</span></a></li>
                                        <li className="post-likes"><a href="#">3<span> Likes</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
