import React, { Component } from 'react'

export default class SurveysContant extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-10 pb-19 pt-md-14 pb-md-16 text-center">
                        <div className="row">
                            <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                                <h1 className="display-1 mb-3">Live Surveys</h1>
                                <p className="lead px-lg-5 px-xxl-8">Welcome to our Panel. Here you can earn extra money from live surveys.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
