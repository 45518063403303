import React, { Component } from 'react';

export default class ContactForm extends Component {
  render(
      
  ) {
    return (
        <div>
            <section className="wrapper bg-soft-primary">
                <div className="container-device py-8">      
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-lg-1 offset-md-2">
                            <h2 className="display-4 mb-3 text-center">Drop Us a Line</h2>
                            <p className="lead text-center mb-10">Reach out to us from our contact form and we will get back to you shortly.</p>
                            <form className="contact-form" method="post" action="php/contact.php">
                                <div className="messages"></div>
                                <div className="controls">
                                    <div className="row gx-4">
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="form_name" type="text" name="name" className="form-control" placeholder="Jane" required="required" data-error="First Name is required."/>
                                                <label for="form_name">First Name *</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="form_lastname" type="text" name="surname" className="form-control" placeholder="Doe" required="required" data-error="Last Name is required."/>
                                                <label for="form_lastname">Last Name *</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="form_email" type="email" name="email" className="form-control" placeholder="jane.doe@example.com" required="required" data-error="Valid email is required."/>
                                                <label for="form_email">Email *</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="form_phone" type="tel" name="phone" className="form-control" placeholder="Your phone number"/>
                                                <label for="form_phone">Phone</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating mb-4">
                                                <textarea id="form_message" name="message" className="form-control" placeholder="Your message" rows="5" required="required" data-error="Message is required." style={{ height:"150px"}}></textarea>
                                                <label for="form_message">Message *</label>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <input type="submit" className="btn btn-primary rounded-pill btn-send mb-3" value="Send message"/>
                                            <p className="text-muted"><strong>*</strong> These fields are required.</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
  }
}

