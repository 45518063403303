import React, { Component } from 'react'

export default class ReachPdp extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device py-8">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <h3 className="display-4 mb-3">Profiling Data Points</h3>
                                <p className='text-justify'>Samcrit respondents are profiled on arrange of standard characteristics. Additional screening and feasibility data may be available in certain locations.</p>
                            </div>
                            <div className='col-lg-4'>
                                <div className="card bg-pale-yellow">
                                    <div>
                                        <ul className="my-4 ">
                                            <li>Age</li>
                                            <li>Gender</li>
                                            <li>Zip</li>
                                            <li>Race / Ethnicity</li>
                                            <li>State</li>
                                            <li>Dma</li>
                                            <li>Region</li>
                                            <li>Relationship</li>
                                            <li>Education</li>
                                            <li>Voter Registration</li>
                                            <li>Sexual orientation Industry</li>
                                            <li>Purchasing Decision maker</li>
                                            <li>Company's no. of employees</li>
                                            <li>Company revenue</li>
                                            <li>Company department</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="card bg-pale-red">
                                    <div>
                                        <ul className="my-4 ">
                                            <li>Age</li>
                                            <li>Gender</li>
                                            <li>Zip</li>
                                            <li>Race / Ethnicity</li>
                                            <li>State</li>
                                            <li>Dma</li>
                                            <li>Region</li>
                                            <li>Relationship</li>
                                            <li>Education</li>
                                            <li>Voter Registration</li>
                                            <li>Sexual orientation Industry</li>
                                            <li>Purchasing Decision maker</li>
                                            <li>Company's no. of employees</li>
                                            <li>Company revenue</li>
                                            <li>Company department</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="card bg-pale-leaf">
                                    <div>
                                        <ul className="my-4 ">
                                            <li>Gaming device</li>
                                            <li>Gaming online</li>
                                            <li>Radio frequency</li>
                                            <li>Flight purpose</li>
                                            <li>Hotel type</li>
                                            <li>Smoking</li>
                                            <li>Eyeware</li>
                                            <li>Hearing aid</li>
                                            <li>Age and gender of child</li>
                                            <li>Employment</li>
                                            <li>Car model us standard</li>
                                            <li>Diagnosed ailments</li>
                                            <li>Household income</li>
                                            <li>County Race/ethnicity</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
