import React, { Component } from 'react'
import Icon01 from '../../assets/img/icons/clock.png'
import Icon02 from '../../assets/img/icons/verified.png'
import Icon03 from '../../assets/img/icons/briefcase.png'

export default class MainWork extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8 pb-12">
                        <div className="row mb-lg-n3">
                            <div className="col-lg-4">
                                <h3 className="display-4 mb-3 pe-xl-15">We Are Proud Of Our Works</h3>
                                <p className="lead fs-lg mb-0 pe-xxl-10">We bring solutions to make life easier for our customers.</p>
                            </div>
                            <div className="col-lg-8 col-sm-12 mt-lg-2">
                                <div className="row align-items-center counter-wrapper gy-6 text-center">
                                    <div className="col-sm-4">
                                        <img src={Icon01} className="text-primary mb-3" alt="" />
                                        <h3 className="counter">4M+</h3>
                                        <p>Our Panelist</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <img src={Icon02} className="text-primary mb-3" alt="" />
                                        <h3 className="counter">52+</h3>
                                        <p>Surving Countries</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <img src={Icon03} className="text-primary mb-3" alt="" />
                                        <h3 className="counter">100M+</h3>
                                        <p>Completed Surveys</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
