import React, { Component } from 'react'
import BlogPostContant from './component/MainComponent/BlogPostContant'
import BlogPostSec from './component/MainComponent/BlogPostSec'
import Footer from './include/Footer'
import Header from './include/Header'

export default class BlogPost extends Component {
    render() {
        return (
            <div>
                <Header/>
                <BlogPostContant/>
                <BlogPostSec/>
                <Footer/>
            </div>
        )
    }
}
