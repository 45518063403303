import React, { Component } from 'react'
import Banner01 from '../../assets/img/photos/pp1.jpg'
import Banner02 from '../../assets/img/photos/pp2.png'
import Banner03 from '../../assets/img/photos/pp3.png'
import Banner04 from '../../assets/img/photos/pp4.png'
import Banner05 from '../../assets/img/photos/pp5.png'

export default class InsightPostSec extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-light wrapper-border">
                    <div className="container pb-14 pb-md-16">
                        <div className="row">
                            <div className="col-12">
                                <article className="mt-n21">
                                    <figure className="rounded mb-8 mb-md-12"><img src={Banner01} alt="" /></figure>
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1">
                                            <h2 className="display-6 mb-4">COVID Impact</h2>
                                            <div className="row gx-0">
                                                <div className="col-md-9 text-justify">
                                                    <p>Professional and personal life under quarantine due to the hashtag#covid19. This virus put a huge impact on the world that everyone is under lockdown and that changed everything form professional to personal life, from school/college life to vacation plans. 
                                                    This virus made the huge impact on us in very short time. Mostly every country is facing challenges due to this. Countries like USA, Spain, Italy has faced almost 1500 death per day. 
                                                    But the main question is still here that have we faced the worst part of it or worst is still yet to come.</p>
                                                </div>
                                                <div className="col-md-2 ms-auto">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <h5 className="mb-1">Date</h5>
                                                            <p>17 May 2018</p>
                                                        </li>
                                                        <li>
                                                            <h5 className="mb-1">Client Name</h5>
                                                            <p>Cool House</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                                        <div className="item col-md-6">
                                            <figure className="rounded"><img src={Banner02} alt="" /></figure>
                                        </div>
                                        <div className="item col-md-6">
                                            <figure className="rounded"><img src={Banner03} alt="" /></figure>
                                        </div>
                                        <div className="item col-md-6">
                                            <figure className="rounded"><img src={Banner04} alt="" /></figure>
                                        </div>
                                        <div className="item col-md-6">
                                            <figure className="rounded"><img src={Banner05} alt="" /></figure>
                                        </div>
                                    </div>
                                    <div className="row mt-8 mt-md-12">
                                        <div className="col-md-10 offset-md-1 text-justify">
                                            <h2 className="mb-4">COVID Impact</h2>
                                            <p>As per conducted study from 20th April to 28th April we received the response and found that 42% people believe that we have faced the worst conditions from this virus and 58% people think that worst is lying in near future. 
                                                The numbers also revealed a rise and a fall in the health fears of people where the virus is concerned. 
                                                Due to this virus most of the families financial stability got impacted and they are very concerned about that. 
                                                That is a sharp and it suggests that peoples may think that they need to settle in for the long-haul. After all, a “new normal” is only required when people believe the world is changing for an extended period of time.</p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
