import React, { Component } from 'react'
import CF01 from "../../assets/img/concept/AR.png"
import CF02 from "../../assets/img/concept/CO.png"
import CF03 from "../../assets/img/concept/BR.png"
import CF04 from "../../assets/img/concept/CL.png"

export default class SouthAmerica extends Component {
    render() {
        return (
            <div>
                <div>
                    <h3 className="display-4 mb-5">North America</h3>
                    <li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF01} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Argentina"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Argentina</h6>
                            </div>
                        </div>
                    </li><li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF02} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Columbia"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Columbia</h6>
                            </div>
                        </div>
                    </li><li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF03} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Brazil"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Brazil</h6>
                            </div>
                        </div>
                    </li><li className="mb-5"style={{listStyle:"none"}}>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <img src={CF04} style={{ width:"4em", lineHeight:"0em", display: "inline-block", verticalAlign: "middle" }} alt="Chile"/>
                            </div>
                            <div className='col-sm-10'>
                            <h6 className='ms-4'>Chile</h6>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        )
    }
}
