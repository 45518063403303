import React, { Component } from 'react'
import smimg02 from "../../assets/img/concept/concept15.png"

export default class ServiceQues extends Component {
    render() {
        return (
            <div>
                <section className="wrapper bg-soft-primary">
                    <div className="container-device pt-8">
                        <div className="row pb-8 text-right align-items-center">
                            <div className="col-lg-7 order-lg-2">
                                <figure><img className="w-auto" src={smimg02} alt="" /></figure>
                            </div>
                            <div className="col-lg-5 text-left">
                                <h3 className="display-4 mb-5">Questionnaires</h3>
                                <p className="text-justify">Questionnaires are research instruments consisting in a set of questions or other types of prompts that aim to collect information from a respondent. They can be both qualitative as well as quantitative in nature and may or may not be delivered in the form of a survey, but a survey always consists of a questionnaire. 
                                They typically offer a mix of close-ended questions and open-ended questions, with the long form questions offering the ability for the respondent to elaborate on their thoughts. 
                                The questionnaire design depends on the type of information that is required to be collected, with qualitative questionnaires being used when there is a need to collect exploratory information or proving or disproving a hypothesis, while quantitative questionnaires being used to validate or test any previously generated hypothesis.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
